import axios from 'axios'
import AppConfig from '../config/AppConfig';
import { setApiHeader } from '../Helpers';

export const FetchTopic = async(dispatch, id, token) => {
	const { global } = AppConfig
	
	return axios.get(`${global.apiUrl}/topic/${id}`, { headers: setApiHeader(token) })
	.then((res) => {
		dispatch({ type: 'fetch_topic', payload: res.data })
	})
	.catch((err) => {
		dispatch({ type: 'notification', payload: { message: err, type: 'error' } })
	})
}

