import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {FetchDol, FetchDolAssociatedTags, FetchDolAssociatedTopics,} from '../../dispatchers';
import {useStateContext} from '../../Store/Store';
import PageLoader from '../../components/PageLoader';
import Summary from '../../components/Profile/Summary';
import HashtagsUsed from '../../components/HashtagsUsed';
import HashtagsUsedPagination from '../../components/HashtagsUsedPagination';
import AssociatedTags from '../../components/Profile/AssociatedTags';
import AssociatedTopics from '../../components/Profile/AssociatedTopics';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

import './styles.scss';

const ProfilePage = () => {
  const {id} = useParams<{ id: string }>()
  const {state, dispatch} = useStateContext()
  const {
    dol,
    dolAssociatedTags,
    dolAssociatedTopics,
    appConfig,
    user,
  } = state
  const {
    global: {
      associatedTagLabel,
      ActivityInLabel,
      hashtagsUsedTitle,
    },
    topicStrings: {
      noTopicsMessage,
    },
    tagStrings: {
      noTagsMessage,
    },
  } = appConfig

  useEffect(() => {
    if (user.token) {
      FetchDol(dispatch, id, user.token)
      FetchDolAssociatedTags(dispatch, id, user.token)
      FetchDolAssociatedTopics(dispatch, id, user.token)
    }
  }, [dispatch, id, user.token])

  const isLoading = !dol || !dolAssociatedTags || !dolAssociatedTopics

  if (isLoading) return <PageLoader/>

  return (
    <div className='grid-wrapper'>
      <div className='flex-item left-flex-item'>
        <Summary
          topic_id={dolAssociatedTopics[0].topic_id}
          centered
          dol={dol}
        />
      </div>
      <div className='twitter-feed'>
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName={dol.screen_name}
          options={{ height: '100%' }}
        />
      </div>
      <div className='profile-tags'>
        <AssociatedTags
          tags={dolAssociatedTags}
          title={associatedTagLabel}
          noTagsMessage={noTagsMessage}
        />
        <AssociatedTopics
          topics={dolAssociatedTopics}
          title={ActivityInLabel}
          noTopicsMessage={noTopicsMessage}
        />
        <div className="profile-data">
          <HashtagsUsedPagination userId={id}>
           <HashtagsUsed userId={id} title={hashtagsUsedTitle} />
          </HashtagsUsedPagination>
        </div>
      </div>
    </div>
  )
}

export default ProfilePage
