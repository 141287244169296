import { USER } from './types'

/**
 * These are the default user settings for insights reporting page
 * These settings will be added when a new user has been created
 * Also these settings have been applied only once to all existing users
 */
export const defaultUserSettings = [
  { label: 'Topic', key: 'heading', is_active: true, exclude_from_ui: true },
  {
    label: 'Listening Imperative',
    key: 'question',
    is_active: true,
    exclude_from_ui: true,
  },
  { label: 'Insight', key: 'answer', is_active: true },
  { label: 'Actions', key: 'actions', is_active: true },
  { label: 'Practice Setting(s)', key: 'practice_setting', is_active: true },
  { label: 'Primary role(s)', key: 'primary_role', is_active: false },
  { label: 'Recognition Level', key: 'recognition_level', is_active: false },
  { label: 'Disease state(s)', key: 'disease_state', is_active: false },
  { label: 'Which product(s)', key: 'which_products', is_active: false },
  { label: 'Insight Categories', key: 'categorize_insight', is_active: false },
  { label: 'KOL Name', key: 'kol', is_active: true },
  { label: 'MSL Name', key: 'user', is_active: true },
  { label: 'Interaction Date', key: 'interview_start_time', is_active: true },
  { label: 'Insight Captured At', key: 'created_at', is_active: false },
  { label: 'Tags', key: 'tags', is_active: true },
]

export function setUser(dispatch, userData) {
  const { id, name, email, users_roles, user_settings } = userData
  const { role } = users_roles?.[0] || {}
  dispatch({
    type: USER,
    payload: {
      id,
      name,
      email,
      role: role?.title || null,
      settings: user_settings?.[0] || [],
      permissions: [
        ...new Set(
          role?.role_permissions?.map(({ permission }) => permission?.title) ||
            []
        ),
      ],
    },
  })
}
