import axios from 'axios'
import AppConfig from '../config/AppConfig';
import { setApiHeader } from '../Helpers';

export const PutTagSearchString = async(dispatch, searchString, token) => {
  const { global } = AppConfig;

  return axios
    .put(`${global.apiUrl}/search_string`, searchString, {headers: setApiHeader(token)})
    .then((res) => {
      dispatch({
        type: 'notification',
        payload: { message: 'Topic updated successfully', type: 'success' },
      })
      window.location.reload()
    })
    .catch((err) => {
      dispatch({
        type: 'notification',
        payload: { message: err, type: 'error' },
      })
      console.error(
        `A problem occured while updating search string ID ${searchString.search_string_id}: `,
        err
      )
    })
}
