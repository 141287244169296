// Note: DO NOT store sensitive information here
// this file is only for strings that will be used
// throughout the application and toggles to allow us
// to turn functionality etc. on or off
const AppConfig = {
  global: {
    audience: process.env.NX_AUDIENCE,
    authClientId: process.env.NX_OAUTH_CLIENT_ID,
    authDomain: process.env.NX_AUTH_DOMAIN,
    clientSecret: process.env.NX_CLIENT_SECRET,
    siteTitle: process.env.NX_BRAND_NAME,
    siteLogo: process.env.NX_BRAND_IMAGE,
    welcome: 'Welcome to DOL Profiler',
    pageError: 'A problem occurred loading this page',
    apiUrl: process.env.NX_TWITTER_REST_API,
    twitterUrl: 'https://twitter.com/',
    twitterProfileLink: 'View Twitter profile',
    hashtagsUsedTitle: 'Hashtags used',
    associatedTagLabel: 'Associated tags',
    ActivityInLabel: 'Activity in',
    activeLabel: 'Active',
    backButton: 'Back',
    cancelButton: 'Cancel',
    editButton: 'Edit',
    viewButton: 'View',
    dolProfileLink: 'View DOL Profile',
    topDolsButton: "View top DOL's",
    downloadSpreadsheet: 'Download spreadsheet',
    browseNetworkLabel: 'Browse Network',
    createTag: 'Create Tag',
    createTopic: 'Create Topic',
    searchProfileIntro: 'Please type your search term',
    searchProfileSearching: 'Searching...',
    searchProfileNoResults: 'No results found',
    profileButtonLabel: 'View full profile',
    dolNetworkPageDescription: 'Discover relevant Digital Opinion Leaders (DOLs)',
    savedDolNetworkPageDescription: 'View your saved DOL profiles',
    profilePreviewDefaultText: 'Please select a DOL from the list',
    profilePreviewSubheading: 'Profile Preview',
    profilePreviewDescription: 'Evaluate the relevancy of this DOL.',
    verifiedIconDescription: "This account is verified by Twitter because it's notable in government, news, entertainment, or another designated category.",
    followersLabel: 'followers',
    followingLabel: 'following',
    tweetCountLabel: 'tweets',
  },
  topicStrings: {
    title: 'Topics',
    noTopicsMessage: 'No Topics found',
    topicDefinition: 'Topic definition',
    topicDolsTitle: "Top DOL's for",
    topicDolTabs: {
      authority: {
        text: 'By authority',
        tabKey: 'by-authority',
      },
      influence: {
        text: 'By influence',
        tabKey: 'by-influence',
      },
    },
    form: {
      title: 'New Topic',
      fields: {
        name: {
          error: 'Please provide a valid topic name',
          label: 'Name',
          name: 'topic_text',
          placeholder: 'enter a name',
        },
        description: {
          error: 'Please provide a valid topic description',
          label: 'Description',
          name: 'description',
          placeholder: 'enter a description',
        },
        hashtags: {
          error: 'You must add at least one hashtag or a search string',
          label: 'Hashtags',
          name: 'hashtags',
          placeholder:
            'enter any hashtags you want to include separated by a comma',
        },
        searchString: {
          error: 'You must add at least one hashtag or a search string',
          label: 'Search string',
          name: 'search_string',
          placeholder: 'enter a search string',
        },
        fromDate: {
          label: 'From',
          name: 'from_date',
          placeholder: '',
        },
        toDate: {
          label: 'To',
          name: 'to_date',
          placeholder: '',
        },
        submit: {
          label: 'Submit',
        },
      },
    },
  },
  tagStrings: {
    title: 'Tags',
    noTagsMessage: 'No Tags found',
    tagDefinition: 'Tag definition',
    form: {
      title: 'New Tag',
      fields: {
        name: {
          error: 'Please provide a valid tag name',
          label: 'Name',
          name: 'tag_text',
          placeholder: 'enter a name',
        },
        description: {
          error: 'Please provide a valid tag description',
          label: 'Description',
          name: 'description',
          placeholder: 'enter a description',
        },
        submit: {
          label: 'Submit',
        },
      },
    },
  },
  navigation: {
    home: {
      title: 'Home',
      path: '/',
      isNavigation: true,
    },
    search: {
      title: 'Search',
      path: '/search',
      isNavigation: true,
    },
    topics: {
      title: 'Manage Topics',
      path: '/topics',
      isNavigation: true,
      children: {
        create: {
          title: 'Create Topic',
          path: '/topics/create',
          isNavigation: true,
        },
        view: {
          title: 'View Topic',
          path: '/topic/:id',
          isNavigation: false,
        },

        edit: {
          title: 'Edit Topic',
          path: '/topic/:id/edit',
          isNavigation: false,
        },
        dols: {
          title: "Top DOL's",
          path: '/topic/:id/dols',
          isNavigation: false,
        },
      },
    },
    tags: {
      title: 'Manage Tags',
      path: '/tags',
      isNavigation: true,
      children: {
        create: {
          title: 'Create Tag',
          path: '/tag/create',
          isNavigation: true,
        },
        view: {
          title: 'Tag Details',
          path: '/tag/:id',
          isNavigation: false,
        },
        edit: {
          title: 'Tag Edit',
          path: '/tag/:id/edit',
          isNavigation: false,
        },
        dols: {
          title: "Top DOL's",
          path: '/tag/:id/dols',
          isNavigation: false,
        },
      },
    },
    profile: {
      title: 'DOL Profile',
      path: '/profile/:id',
      isNavigation: false,
    },
    networkInfluencers: {
      title: 'Browse Network',
      path: '/topic/:id/network',
      isNavigation: false
    }
  },
}

export default AppConfig
