import { ProfileImage } from '@visformatics/image'
import { UserSearch } from './interface'
import Details from '../../Profile/Summary/Details'
import TwitterStats from '../../TwitterStats'
import alternateImage from '../../../assets/profile_image_placeholder.png'

const UserCard = ({
  description,
  id,
  name,
  image,
  location,
  screenName,
  followers_count,
  statuses_count,
  friends_count,
  twitterRootUrl,
  verified,
}: UserSearch) => {
  const twitterUrl = `${twitterRootUrl}${screenName}`

  return (
    <div className="summary-container">
      <div className="profile-info">
        <ProfileImage
          round={true}
          image={image}
          size="small"
          alt={name}
          alternateImage={alternateImage}
        />
        <Details
          name={name ?? 'No name provided'}
          verified={verified}
          screenName={screenName ?? 'Unknown'}
          location={location ?? 'No location provided'}
          description={description ?? 'No description provided'}
          twitterUrl={twitterUrl}
        />
        <TwitterStats
          followerCount={followers_count}
          tweetCount={statuses_count}
          friendsCount={friends_count}
        />
      </div>
    </div>
  )
}

export default UserCard
