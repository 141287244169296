import './styles.scss';
import { TagType } from '../../../Types';
import AsscoatedTagsInterface from './Interface';

const AssociatedTags = ({ tags, title, noTagsMessage }: AsscoatedTagsInterface) => {
  if (tags?.length === 0) return (
    <div className="associated-tags">
      <h3>{title}</h3>
      <hr/>
      <p>{noTagsMessage}</p>
    </div>
  );

  return (
    <div className="associated-tags">
      <h3>{title}</h3>
      <hr/>
      <ul>
        {tags && tags.map((tag: TagType) => (
          <li key={`tag ${tag.tag_id}`}>
            {tag.tag_text}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AssociatedTags;
