import './styles.scss';
import { DolTopicType } from '../../../Types';
import AssociatedTopicsInterface from './Interface';

const AssociatedTopics = ({ topics, title, noTopicsMessage }: AssociatedTopicsInterface) => {
  if (topics?.length === 0) return (
    <div className="associated-topics">
      <h3>{title}</h3>
      <hr/>
      <p>{noTopicsMessage}</p>
    </div>
  );

  return (
    <div className="associated-topics">
      <h3>{title}</h3>
      <hr/>
      <ul>
        {topics?.map((topic: DolTopicType) => (
          <li key={`topic ${topic.topic_id}`}>
            {topic.topic_text}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AssociatedTopics;
