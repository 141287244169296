import React from 'react'
import { useStateContext } from '../../Store/Store'
import './styles.scss'

const LandingPage = () => {
  const { state } = useStateContext()
  const {
    appConfig: { global },
  } = state

  return (
    <div className="homepage">
      <h1>{global.welcome}</h1>
    </div>
  )
}

export default LandingPage
