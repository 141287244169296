import { useState } from 'react'
import { FlatButton, Input } from '@visformatics/components'
import searchIcon from '../../assets/search-solid.svg'
import './styles.scss'

type UserSearchProps = {
  click?: (text: string) => void
}

export const SearchForm = ({ click }: UserSearchProps) => {
  const [value, setValue] = useState('')

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSearch = () => {
    click(value)
  }

  const handleClear = () => {
    setValue('')
  }

  return (
    <div className="search-bar">
      <div className="search-bar_container">
        <img className="search-bar__icon" src={searchIcon} alt="Search Icon" />
        <div className="search-bar__input">
          <Input
            labelText="Search users"
            change={handleChange}
            keyPress={handleKeyPress}
            value={value}
          />
        </div>
        <div className="search-bar_actions">
          <FlatButton click={handleSearch} children={'Search'} />
          <FlatButton click={handleClear} children={'Clear'} />
        </div>
      </div>
    </div>
  )
}

export default SearchForm
