/**
 * List of values that are used to create helper classes for (in pixels)
 * e.g. m0 - margin: 0px
 */
const values = [
  '0',
  '5',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '40',
  '45',
  '50',
]

/**
 * Loop through list of values and generate classes
 * @return Object - object of generated classes
 */
const prepareClasses = () => {
  let output = {}

  const classes = values.map((v) => {
    const value = `${v}px`
    return {
      [`m${v}`]: { margin: value },
      [`mb${v}`]: { marginBottom: value },
      [`mt${v}`]: { marginTop: value },
      [`mr${v}`]: { marginRight: value },
      [`ml${v}`]: { marginLeft: value },
    }
  })

  Object.keys(classes).map((key) => {
    let obj = classes[key]
    output = {
      ...output,
      ...obj,
    }
    return {}
  })

  return output
}

/**
 * Export object of margins
 */
export const margins = {
  mAuto: {
    margin: '0 auto',
  },
  ...prepareClasses(),
}
