import React from 'react'

import { Text } from '../../typography/text/Text'

import './TextArea.css'

type Props = {
  change?: (event) => void
  error?: string
  info?: string
  labelText: string
  value?: string
}

export const TextArea = ({ change, error, info, labelText, value }: Props) => {
  return (
    <>
      <div className="float-label">
        <textarea onChange={change} value={value} />
        <label>{labelText}</label>
      </div>
      {error && <Text type="caption">{error}</Text>}
      {info && !error && <Text type="caption">{info}</Text>}
    </>
  )
}
