import React from 'react'
import PropTypes from 'prop-types'

export const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>
        {error && error.message
          ? error.message
          : 'Please contact your system administrator'}
      </pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

ErrorFallback.propTypes = {
  error: PropTypes.shape({}),
  resetErrorBoundary: PropTypes.func,
}
export default ErrorFallback
