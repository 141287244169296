import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { groupBy } from '@visformatics/utils'
import ReportingTable from '../tables/ReportingTable'
import ReactMarkdown from 'react-markdown'
import { Loader } from '../loader/loader'
import './accordion.scss'

export const Accordion = ({ data, isLoading, toggleAll, dataColumns }) => {
  const [expanded, setExpanded] = useState({})

  // If there is only one Heading available, expand it
  useEffect(() => {
    if (Object.keys(data).length === 1) {
      setExpanded({ 0: true })
    }
  }, [data])

  return (
    <div className="accordion">
      {isLoading && <Loader loader={true} />}
      {data &&
        Object.values(data).map((insights, index) => {
          const [firstElement] = insights
          const { heading } = firstElement
          const groupedInsights = groupBy(insights, 'question')

          return (
            <div className="w-full overflow-hidden border-t tab" key={index}>
              <input
                className="absolute opacity-0"
                type="radio"
                name={`radio-${index}`}
                checked={toggleAll ? true : expanded[index] || false}
                onChange={(event, isExpanded) =>
                  setExpanded({ ...expanded, [index]: isExpanded })
                }
              />
              <label
                className="block p-5 leading-normal bg-white cursor-pointer"
                onClick={() =>
                  setExpanded({ ...expanded, [index]: !expanded[index] })
                }
                htmlFor={`radio-${index}`}
              >
                <span className="label-span">
                  {heading || 'Reporting Results'}
                </span>
              </label>
              <div className="overflow-hidden leading-normal bg-white border-l-2 border-primary-500 tab-content">
                {Object.values(groupedInsights).map((questions, i) => {
                  const [firstElement] = questions
                  const { question } = firstElement
                  return (
                    <div className="p-5" key={i}>
                      <div className="my-4 subtitle markdown">
                        <ReactMarkdown>{question}</ReactMarkdown>
                      </div>

                      <ReportingTable
                        questions={questions}
                        dataColumns={dataColumns}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
    </div>
  )
}

Accordion.propTypes = {
  data: PropTypes.shape({}),
  dataColumns: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  toggleAll: PropTypes.bool,
}

export default Accordion
