import { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useStateContext } from '../../../Store/Store'
import PageLoader from '../../../components/PageLoader'
import { FetchTag, PutTag } from '../../../dispatchers'
import TagForm from '../../../components/Tags/Form'
import './styles.scss'

const EditTag = () => {
  const { id } = useParams<{ id: string }>()
  const {
    state: {
      appConfig: {
        global,
        tagStrings: {
          form: { fields },
        },
      },
      tag,
      user,
    },
    dispatch,
  } = useStateContext()
  const history = useHistory()

  useEffect(() => {
    FetchTag(dispatch, id, user.token)
  }, [dispatch, id, user.token])

  if (!tag) return <PageLoader />

  const handleSubmit = (data) => PutTag(dispatch, data, user.token);
  const handleCancel = () => history.goBack()

  return (
    <div className="tag">
      <h1>Edit {tag.tag_text}</h1>
      <TagForm
        labels={fields}
        cancelLabel={global.cancelButton}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        values={tag}
      />
    </div>
  )
}

export default EditTag
