import LoginPage from '../pages/login'
import LandingPage from '../pages/landing'
import ProfilePage from '../pages/ProfilePage'
import AppConfig from '../config/AppConfig'
import SearchPage from '../pages/search'
import { TopicList, Topic, NewTopic, EditTopic } from '../pages/Topics'
import { TagsList, NewTag, ViewTag, EditTag, TagDols } from '../pages/Tags'
import { NetworkInfluencers } from '../pages/Network'
import PageNotFound from '../pages/PageNotFound'

const { navigation } = AppConfig

const Routes = [
  {
    path: '/login',
    exact: true,
    protected: false,
    component: LoginPage,
  },
  {
    path: navigation.home.path,
    exact: true,
    protected: true,
    component: LandingPage,
  },
  {
    path: navigation.profile.path,
    exact: true,
    protected: true,
    component: ProfilePage,
  },
  {
    path: navigation.search.path,
    exact: true,
    protected: true,
    component: SearchPage,
  },
  {
    path: navigation.topics.path,
    exact: true,
    protected: true,
    component: TopicList,
  },
  {
    path: navigation.topics.children.view.path,
    exact: true,
    protected: true,
    component: Topic,
  },
  {
    path: navigation.topics.children.create.path,
    exact: true,
    protected: true,
    component: NewTopic,
  },
  {
    path: navigation.topics.children.edit.path,
    exact: true,
    protected: true,
    component: EditTopic,
  },
  {
    path: navigation.tags.path,
    exact: true,
    protected: true,
    component: TagsList,
  },
  {
    path: navigation.tags.children.create.path,
    exact: true,
    protected: true,
    component: NewTag,
  },
  {
    path: navigation.tags.children.view.path,
    exact: true,
    protected: true,
    component: ViewTag,
  },
  {
    path: navigation.tags.children.edit.path,
    exact: true,
    protected: true,
    component: EditTag,
  },
  {
    path: navigation.tags.children.dols.path,
    exact: true,
    protected: true,
    component: TagDols,
  },
  {
    path: navigation.networkInfluencers.path,
    exact:true,
    protected: true,
    component: NetworkInfluencers,
  },
  {
    exact: true,
    protected: true,
    component: PageNotFound
  }
]

export default Routes
