import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

export const PromptForm = ({ label, onUpdate, onCancel }) => {
  const [seconds, setSeconds] = useState(4)
  const [isDisabled, setIsDisabled] = useState(true)
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000)
    } else {
      setSeconds('Proceed')
      setIsDisabled(false)
    }
  }, [seconds])

  return (
    <>
      <div className="grid grid-cols-1 gap-3">
        <label className="block">
          <span className="text-gray-700">{label}</span>
        </label>
      </div>
      <div className="modal-action__container">
        <button
          type="button"
          className="button-danger"
          onClick={onUpdate}
          disabled={isDisabled}
        >
          {seconds !== 'Proceed' ? `${seconds}...` : seconds}
        </button>
        <button type="button" className="button-cancel" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </>
  )
}

PromptForm.propTypes = {
  label: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}
