import React, { useState } from 'react'
import { MenuIcon } from '@heroicons/react/solid'
import { XIcon } from '@heroicons/react/outline'
import { NavLink } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import LogoutMenu from '../../menus/logout-menu'
import SideNavbar from './side-navbar'
import { SelectEnvironment } from '../../form'
import './navbar.scss'

export function Navbar({ routes }) {
  const [open, setOpen] = useState(false)
  const { logout } = useAuth0()
  return (
    <header className="header">
      <div className="flex items-center">
        <button
          className=" btn-menu hover:opacity-75 custom-transition"
          onClick={() => setOpen(true)}
          type="button"
        >
          {open ? (
            <XIcon className="w-6 h-6" aria-hidden="true" />
          ) : (
            <MenuIcon className="w-6 h-6" aria-hidden="true" />
          )}
        </button>
        <span className="ml-2 text-lg font-bold">
          {process.env.NX_BRAND_NAME}
        </span>
      </div>

      <SideNavbar open={open} setOpen={setOpen} title="Dashboard Admin">
        <div className="flex bg-gray-100">
          <div className="flex w-full bg-white">
            <ul className="flex flex-col w-full">
              {routes &&
                routes.map(({ title, path, icon, includeInNav }, index) => {
                  return (
                    includeInNav && (
                      <li className="my-px" key={index}>
                        <NavLink
                          to={path}
                          className="nav-link"
                          activeClassName="active-link"
                          exact
                        >
                          <span className="flex items-center justify-center text-lg nav-icon">
                            {icon}
                          </span>
                          <span className="ml-3">{title}</span>
                        </NavLink>
                      </li>
                    )
                  )
                })}

              <li className="my-px">
                <span className="flex px-4 my-4 text-sm font-medium text-gray-400 uppercase">
                  Account
                </span>
              </li>

              <li className="my-px">
                <NavLink
                  to="#sign-out"
                  onClick={() =>
                    logout({ returnTo: `${window.location.origin}/login` })
                  }
                  className="flex flex-row items-center h-12 px-4 text-gray-600 rounded-lg hover:bg-gray-100"
                >
                  <span className="flex items-center justify-center text-lg text-red-400">
                    <svg
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"></path>
                    </svg>
                  </span>
                  <span className="ml-3">Logout</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </SideNavbar>

      <div className="relative flex items-center justify-between">
        <div className="mr-4">
          <SelectEnvironment />
        </div>

        <LogoutMenu />
      </div>
    </header>
  )
}
export default Navbar
