import { useHistory } from 'react-router-dom';
import TagForm from '../../../components/Tags/Form';
import { useStateContext } from '../../../Store/Store';
import { PostTag } from '../../../dispatchers';

import './styles.scss'

const NewTag = () => {
  const { state, dispatch } = useStateContext();
  const {
    appConfig: {
      tagStrings: {
        form: { fields, title },
      },
      global,
    },
    user,
  } = state
  const history = useHistory();
  const handleSubmit = (data) => PostTag(dispatch, data, user.token);
  const handleCancel = () => history.goBack();

  return (
    <div className="topic-form-container">
      <h1>{title}</h1>
      <TagForm
        labels={fields}
        cancelLabel={global.cancelButton}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </div>
  )
}

export default NewTag
