import { ENVIRONMENT } from './types'
export const reducer = (state, action) => {
  switch (action.type) {
    case ENVIRONMENT:
      return {
        ...state,
        environment: {
          ...action.payload,
        },
      }
    default:
      return state
  }
}
