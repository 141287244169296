import TableHead from './TableHead'
import TableBody from './TableBody'
import './styles.scss'

const Table = ({ columns, data }) => {
  return (
    data?.length ? 
      <table>
        <TableHead columns={columns} />
        <TableBody data={data} />
      </table>
    : <h3>
      No data entries found
    </h3>
  )
}

export default Table
