import { useHistory } from 'react-router-dom'
import TopicForm from '../../../components/Topics/TopicForm'
import { useStateContext } from '../../../Store/Store'
import { PostTopic } from '../../../dispatchers'

import './styles.scss'

const NewTopic = () => {
	const { state, dispatch } = useStateContext();
  const { 
    appConfig: { 
      topicStrings: {
        form: {
          fields, 
          title
        }
      }, 
      global 
    }, 
    user 
  } = state
  const history = useHistory()
	
	const handleOnSubmit = (data) => PostTopic(dispatch, data, user.token);
  const handleCancel = () => history.goBack()

	return (
    <div className="topic-form-container">
      <h1>{title}</h1>
      <TopicForm 
        cancelLabel={global.cancelButton}
        labels={fields}
        onSubmit={handleOnSubmit} 
        onCancel={handleCancel}
      />
    </div>
  )
}

export default NewTopic
