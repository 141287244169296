import React, { useEffect, useCallback, useContext, Fragment } from 'react'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ENVIRONMENT, EnvironmentContext } from '@visformatics/providers'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

export const environments = [
  {
    label: 'Testing',
    graphql_uri: `https://medical-api-testing.visformatics.net/v1/graphql`,
  },
  {
    label: 'Development',
    graphql_uri: `https://medical-api-dev.visformatics.net/v1/graphql`,
  },
  {
    label: 'Staging',
    graphql_uri: `https://medical-api-staging.visformatics.net/v1/graphql`,
  },
  {
    label: 'Production',
    graphql_uri: `https://medical-api.visformatics.net/v1/graphql`,
  },
]

export const SelectEnvironment = () => {
  const { state, dispatch } = useContext(EnvironmentContext)

  const setEnvironment = useCallback(
    (label) => {
      let environment = environments.find((env) => env.label === label)
      dispatch({
        type: ENVIRONMENT,
        payload: {
          ...environment,
        },
      })
    },
    [dispatch]
  )

  // On Component load
  useEffect(() => {
    setEnvironment('Testing')
  }, [setEnvironment])

  return (
    <Listbox
      value={state?.environment?.label}
      onChange={(label) => setEnvironment(label)}
      className="z-10 min-w-1/2"
    >
      <div className="relative mt-1">
        <Listbox.Button className="relative z-10 w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
          <span className="block truncate text-primary-600">
            {state?.environment?.label}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {environments.map(({ label }, index) => (
              <Listbox.Option
                key={index}
                className={({ active }) =>
                  `${active ? 'text-primary-900 bg-gray-200' : 'text-gray-600'}
                      cursor-default select-none relative py-2 pl-10 pr-4`
                }
                value={label}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={`${
                        selected ? 'font-medium' : 'font-normal'
                      } block truncate`}
                    >
                      {label}
                    </span>
                    {selected ? (
                      <span
                        className={`${
                          active ? 'text-primary-600' : 'text-primary-600'
                        }
                            absolute inset-y-0 left-0 flex items-center pl-3`}
                      >
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default SelectEnvironment
