import { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Redirect } from 'react-router-dom'
import { Loader } from '@visformatics/components'
import { useStateContext } from '../../Store/Store'
import { useAccessToken } from '../../Hooks'
import Navigation from '../../components/Navigation'
import HamburgerMenu from '../../components/Menu'
import Notification from '../../components/Notification'
import { isAdmin, is24HoursOld } from './helpers/helpers'
import './styles.scss'

export const DefaultLayout = ({ children }) => {
  const [isAdminMenuOpen, setIsAdminMenuOpen] = useState(false)
  const { state, dispatch } = useStateContext()
  const date = Date.now()
  const currentTimeStamp = date
  const {
    appConfig: { navigation },
    user: userState,
    notification,
  } = state
  const {
    error,
    isAuthenticated,
    isLoading,
    user: authUser,
    getAccessTokenSilently,
  } = useAuth0()
  const getToken = useAccessToken(isLoading, getAccessTokenSilently)

  useEffect(() => {
    if (!userState?.token || is24HoursOld(userState?.created_at)) {
      getToken
        .then((response) => {
          dispatch({
            type: 'set_user',
            payload: { token: response?.token, created_at: currentTimeStamp },
          })
        })
        .catch((error) => console.error('Error: ', error))
    }
  }, [getToken, userState, dispatch, currentTimeStamp])

  if (error) return <div>{error.message}</div>
  if (isLoading) return <Loader />
  if (!isAuthenticated) {
    dispatch({
      type: 'set_redirect_url',
      payload: window.location.pathname,
    })
    return <Redirect to="/login" />
  } 

  return (
    <>
      <Notification notification={notification} />
      <div className='container'>
        <div className="menu-section">
          {isAdmin(authUser.email) &&
            <HamburgerMenu
              onClick={() => setIsAdminMenuOpen(!isAdminMenuOpen)}
              isSelected={isAdminMenuOpen}
            />
          }
          {isAdminMenuOpen && (
            <div
              className={`container ${
                isAdmin(authUser.email) ? 'show-nav' : 'hide-nav'
              }`}
            >
              <nav className="main-navigation">
                <Navigation navigation={navigation} />
              </nav>
            </div>
          )}
        </div>
        <main className="main-content">{children}</main>
      </div>
    </>
  )
}
