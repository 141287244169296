import axios from 'axios'
import AppConfig from '../config/AppConfig';
import { setApiHeader } from '../Helpers';

export const FetchDolAssociatedTopics = async(dispatch, id, token) => {
  const { global } = AppConfig

  return axios
    .get(`${global.apiUrl}/user/${id}/topics`, { headers: setApiHeader(token) })
    .then((res) => {
      dispatch({ type: 'fetch_dol_associated_topics', payload: res.data })
    })
    .catch((err) => {
      dispatch({ type: 'notification', payload: {message: err, type: 'error'} })
      console.error('error when fetching associated topics for DOL: ', err)
      dispatch({ type: 'fetch_dol_associated_topics', payload: [] })
    })
}
