import axios from 'axios'
import AppConfig from '../config/AppConfig';
import { setApiHeader } from '../Helpers';

export const PutTopic = async(dispatch, topic, token, redirectUrl?) => {
	const { global } = AppConfig;

	return axios.put(`${global.apiUrl}/topic`, topic, { headers: setApiHeader(token) })
		.then((res) => {
			dispatch({ type: 'notification', payload: { message: 'Topic updated successfully', type: 'success' } })
			window.location.pathname = (redirectUrl) ? redirectUrl : `/topic/${topic.topic_id}`
		})
		.catch((err) => {
			dispatch({ type: 'notification', payload: { message: err, type: 'error' } })
			console.error(`A problem occured while updating Topic ID ${topic.id}: `, err)
		})
}
