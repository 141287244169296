import { DetailsInterface } from './DetailsInterface'
import VerifiedIcon from '../../../VerifiedIcon'
import {ReactComponent as LocationIcon} from '../../../../assets/location-icon.svg'

const Details = ({
  name,
  screenName,
  location,
  description,
  twitterUrl,
  verified,
  centered
}: DetailsInterface) => {
  return (
    <div className={`profile-personal-details ${centered && "centered-column"}`}>
      <h2 className="name_header-container__title">{name} {verified && <VerifiedIcon />}</h2>
      <a href={twitterUrl} className="name_header-container__screen">
        {screenName.startsWith('@') ? screenName : `@${screenName}`}
      </a>
      <p className='location'>
        {(location && 
          <> 
            <LocationIcon
              className='location-pin'
            />
            {location}
          </>)
          ?? 'No location provided'
        }
      </p>
      <p>{description ?? 'No description provided'}</p>
    </div>
  )
}

export default Details
