import {useEffect} from 'react'
import Details from './Details'
import TwitterStats from '../../TwitterStats'
import {DolProfileSummary} from './interface'
import {profileImageUrl, twitterProfileUrl} from '../../../Helpers';
import FavouriteIcon from '@material-ui/icons/Favorite'
import {useStateContext} from '../../../Store/Store'
import {FetchTopicDolsExternalUser} from '../../../dispatchers/FetchTopicDolsExternalUser';

import './styles.scss'

const Summary = ({topic_id, dol, centered = false}: DolProfileSummary) => {
  const {state, dispatch} = useStateContext()
  const {topicDolsByExternalUser, user} = state;
  
  useEffect(() => {
    FetchTopicDolsExternalUser(
      dispatch,
      topic_id,
      'betweenness_centrality',
      user.token,
      100,
      0
    )
  }, [dispatch, topic_id, user.token]);

  const isFavourited = topicDolsByExternalUser?.records.filter(
    ele => ele.user_id === dol.user_id
  )[0]?.is_favorited

  return (
    <div className="summary-container">
      <div className={centered ? 'centered-profile centered-column' : 'profile-info'}>
        <div>
          <img
            src={profileImageUrl(dol.profile_image_url_https)}
            className="avatar"
            alt={`${dol.name} avatar`}
          />
          {isFavourited && <FavouriteIcon className='favourite-icon' />}
        </div>
        <Details
          centered={centered}
          name={dol.name}
          location={dol.location}
          screenName={dol.screen_name}
          description={dol.description}
          twitterUrl={twitterProfileUrl(dol.screen_name)}
          verified={dol.verified}
        />
      </div>
      <hr />
      <TwitterStats
        followerCount={dol.followers_count}
        tweetCount={dol.statuses_count}
        friendsCount={dol.friends_count}
      />
    </div>
  )
}

export default Summary
