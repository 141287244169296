import AppConfig from '../../config/AppConfig';

const TwitterStats = ({ followerCount, friendsCount, tweetCount }) => {
  const { global } = AppConfig;

  return (
    <div className="twitter-stats">
      <div>{followerCount} {global.followersLabel}</div>
      <div>{friendsCount} {global.followingLabel}</div>
      <div>{tweetCount} {global.tweetCountLabel}</div>
    </div>
  )
}

export default TwitterStats
