import { useState } from 'react'
import SearchForm from '../../components/SearchForm'
import SearchResults from '../../components/SearchResults'
import { useStateContext } from '../../Store/Store'
import { UserSearch } from '../../dispatchers'
import './styles.scss'

export const SearchPage = () => {
  const { state, dispatch } = useStateContext()
  const {
    userSearchResults,
    appConfig: { global },
    user,
  } = state
  const [loading, setLoading] = useState(true)

  const handleSearch = (searchTerm: string) => {
    UserSearch(dispatch, searchTerm, user.token)
    setLoading(false)
  }
  
  return (
    <div className="searchpage">
      <div className="search-bar-container">
        <SearchForm click={handleSearch} />
      </div>

      <SearchResults
        users={userSearchResults}
        twitterRootUrl={global.twitterUrl}
        loading={loading}
      />
    </div>
  )
}

export default SearchPage
