import axios from 'axios'
import AppConfig from '../config/AppConfig';
import { setApiHeader } from '../Helpers';

export const FetchTopicDols = async(dispatch, id, measure, token, limit, offset) => {
  const { global } = AppConfig;

  return axios
    .get(`${global.apiUrl}/topic/${id}/dols`, { 
      params: { 
        influence_measure: measure, 
        limit: limit, 
        offset: offset 
      }, headers: setApiHeader(token) })
    .then((res) => {
      measure === 'betweenness_centrality'
        ? dispatch({ type: 'fetch_topic_dols_by_influence', payload: res.data })
        : dispatch({ type: 'fetch_topic_dols_by_authority', payload: res.data })
    })
    .catch((err) => {
      dispatch({ type: 'notification', payload: { message: err, type: 'error' },
      })
    })
}
