import axios from 'axios'
import AppConfig from '../config/AppConfig';
import { setApiHeader } from '../Helpers';

export const PostTagSearchString = async(dispatch, searchString, id, token) => {
  const { global } = AppConfig;

  return axios
    .post(`${global.apiUrl}/search_string`, searchString, { headers: setApiHeader(token) })
    .then((res) => {
      const { search_string_id } = res.data
      return axios
        .post(`${global.apiUrl}/tag/${id}/search_strings`, [search_string_id], { headers: setApiHeader(token) })
        .then((res) => {
          dispatch({ type: 'post_tag_search_string', payload: res.data })
          window.location.pathname = `/tag/${id}`
          dispatch({
            type: 'notification',
            payload: {
              message: 'Search string was created successfully',
              type: 'success',
            },
          })
        })
        .catch((err) =>
          console.error('Error ocurred while submitting search string: ', err)
        )
    })
    .catch((err) => {
      dispatch({
        type: 'notification',
        payload: { message: err, type: 'error' },
      })
      console.error('An error occured while submitting search string: ', err)
    })
}
