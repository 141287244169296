/**
 * Export object of flex classes
 */
export const flex = {
  flex: {
    display: 'flex',
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  flexRow: {
    flexDirection: 'row',
  },
  justifyStart: {
    justifyContent: 'flex-start',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifyBetween: {
    justifyContent: 'space-between',
  },
  justifyAround: {
    justifyContent: 'space-around',
  },
  justifyEvenly: {
    justifyContent: 'space-evenly',
  },
  gap2: {
    gap: '0.5rem',
  },
  gap3: {
    gap: '0.75rem',
  },
  gap4: {
    gap: '1rem',
  },
}
