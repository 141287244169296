import AppConfig from '../config/AppConfig';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import {setApiHeader} from '../Helpers';

export const FetchSavedTwitterUsers = (dispatch, token, topic_id) => {
  const {global} = AppConfig
  const {sub} = jwt_decode<{ sub: string }>(token);

  return axios
    .get(`${global.apiUrl}/external_user/${sub}/favorites/twitter_user`, {headers: setApiHeader(token)})
    .then((res) => {
      dispatch({type: 'fetch_saved_twitter_users', payload: res.data})
    })
    .catch((err) => {
      if (err?.response?.status !== 404) {
        dispatch({type: 'notification', payload: {message: err, type: 'error'}})
        console.error('error when fetching saved twitter users: ', err)
      }
      dispatch({type: 'fetch_saved_twitter_users', payload: null})
    })
}
