import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import Navbar from '../navbars/navbar/navbar'
import Loader from '../loader/loader'
import { Transition } from '@headlessui/react'
import './dashboard-layout.scss'

export const DashboardLayout = ({ routes, children }) => {
  const { isLoading, isAuthenticated, error } = useAuth0()
  const [isSidebarToggled, toggleSidebar] = useState(false)
  const [finishedLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
  }, [setLoading])

  if (isLoading)
    return (
      <div className="flex items-center justify-center w-screen h-screen bg-white opacity-75">
        <Loader />
      </div>
    )
  if (error) return <div>Oops... {error.message}</div>

  if (isAuthenticated) {
    return (
      <>
        <Navbar
          routes={routes}
          toggleSidebar={() => toggleSidebar(!isSidebarToggled)}
        />
        <div className="bg-gray-100 page-content">
          <Transition
            show={finishedLoading}
            appear={true}
            enter="transition-opacity ease-linear duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="flex-1 w-full p-6">{children}</div>
          </Transition>
        </div>
      </>
    )
  } else {
    return <Redirect to="/login" />
  }
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DashboardLayout
