import axios from 'axios'
import AppConfig from '../config/AppConfig';
import { setApiHeader } from '../Helpers';

export const PostTopic = async(dispatch, topic, token) => {
	const { global } = AppConfig;

 	return axios.post(`${global.apiUrl}/topic`, topic, { headers: setApiHeader(token) })
		.then((res) => {
			dispatch({ type: 'fetch_topic', payload: res.data })
			window.location.pathname = `/topic/${res.data.topic_id}`
			
			dispatch({ type: 'notification', payload: { message: 'Topic created successfully', type: 'success' } })
		})
		.catch((err) => {
			dispatch({ type: 'notification', payload: { message: err, type: 'error' } })
			console.error('An error occurred while submitting topic: ', err)
		})

}
