import { useStateContext } from '../../Store/Store'
import { UserSearchType } from '../../Types'
import { Link } from 'react-router-dom'
import UserCard from './UserCard'
import './styles.scss'

type UserSearchTypeProps = {
  users: UserSearchType[]
  twitterRootUrl: string
  loading: boolean
}

const SearchResults = ({
  users,
  twitterRootUrl,
  loading,
}: UserSearchTypeProps) => {
  const { state } = useStateContext()
  const {
    appConfig: { global },
  } = state

  return (
    <div className="search-results-container">
      {!loading &&
        users?.map((user) => (
          <Link key={user.user_id} to={`/profile/${user.user_id}`}>
            <UserCard
              name={user.screen_name}
              location={user.location}
              image={user.profile_image_url_https}
              screenName={user.screen_name}
              description={user.description}
              followers_count={user.followers_count}
              statuses_count={user.statuses_count}
              friends_count={user.friends_count}
              twitterRootUrl={twitterRootUrl}
              verified={user.verified}
            />
          </Link>
        ))}

      {loading && (
        <div className="search-results-container_intro">
          {global.searchProfileIntro}
        </div>
      )}

      {!loading && users && users.length === 0 && (
        <div className="search-results-container_inprogress">
          {global.searchProfileSearching}
        </div>
      )}
      
      {!loading && !users && (
        <div className="search-results-container_noresults">
          {global.searchProfileNoResults}
        </div>
      )}
      
    </div>
  )
}

export default SearchResults
