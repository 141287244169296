import { gql } from '@apollo/client'

/**
 * Get a of question headings
 */
export const GET_QUESTION_HEADINGS = gql`
  query GetQuestionHeadings(
    $filters: [insights_service_question_headings_bool_exp]
  ) {
    insights_service_question_headings(where: { _and: $filters }) {
      id
      title
    }
  }
`

/**
 * Get therapy areas for capture insights
 */
export const GET_CAPTURE_INSIGHTS_TA = gql`
  query GetCaptureInsightsTherapyAreas($is_active: Boolean, $filters: [insights_service_therapy_areas_bool_exp]) {
    insights_service_therapy_areas(
      where: { is_active: { _eq: $is_active }, _and: $filters }
      order_by: { position: asc }
    ) {
      id
      identifier
      display_name
      position
      is_active
    }
  }
`
