import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  PutTag,
  FetchTag,
  FetchTagSearchStrings,
  PostTagSearchString,
} from '../../../dispatchers';
import PageLoader from '../../../components/PageLoader';
import { useStateContext } from '../../../Store/Store';
import { FlatButton, Input } from '@visformatics/components';
import BackButton from '../../../components/BackButton';
import { SearchStringItem } from './SearchStringItem';
import Toggle from '../../../components/Toggle';
import { handleChange, hasEmptyValues } from '../helpers';

import './styles.scss';

const ViewTag = () => {
  const { id } = useParams<{ id: string }>();
  const { state, dispatch } = useStateContext();
  const { tag, tagSearchStrings, user } = state;
  const history = useHistory();

  const {
    appConfig: { global },
  } = state

  const [searchString, setSearchString] = useState({
    search_string: '',
  })

  useEffect(() => {
      FetchTag(dispatch, id, user.token)
      FetchTagSearchStrings(dispatch, id, user.token)
  }, [dispatch, id, user.token])

  if (!tag) return <PageLoader />

  const handleBackClick = () => history.push('/tags');
  const handleEditClick = () => history.push(`/tag/${id}/edit`);

  const isDisabled = hasEmptyValues(searchString)

  const handleAddStringClick = (e) => {
    e.preventDefault();
    PostTagSearchString(dispatch, searchString, id, user.token);
  }

  const handleSearchStringChange = (e) => handleChange(e, setSearchString);

  const handleToggle = (e) => {
    e.preventDefault();
    const togObj = {
      tag_id: id,
      tag_text: tag.tag_text,
      description: tag.description,
      active: !tag.active,
    };
    PutTag(dispatch, togObj, user.token);
  }

  return (
    <div className="tag">
      <div className="calls-to-action">
        <BackButton label="Back" onClick={handleBackClick} />
        <div className="calls-to-action_wrapper">
          <div className="calls-to-action_wrapper__toggle">
            <label htmlFor="active">{global.activeLabel}</label>
            <Toggle
              name="active"
              onChange={handleToggle}
              isChecked={tag.active}
            />
          </div>
          <FlatButton click={handleEditClick} size="medium" children="Edit" />
        </div>
      </div>
      <div className="tag-card tag-details">
        <div className="tag-details-container">
          <div className="tag-details-container_header">
            <h1>{tag.tag_text}</h1>
          </div>
          <div className="tag-details-container_body">
            <div className="tag-details-container_body__field">
              <p>{tag.description ?? 'No description provided'}</p>
            </div>
          </div>
          <div className="tag-details-container_footer">
            <div className="search-strings">
              <h3>Search Strings</h3>
              <div className="search-strings-container">
                <div className="wrapper">
                  <Input
                    name="search_string"
                    labelText="Your string"
                    change={handleSearchStringChange}
                  />
                  <FlatButton
                    disabled={isDisabled}
                    click={handleAddStringClick}
                    children="Add Search String"
                  />
                </div>
                <div className="search-strings-container-list">
                  {tagSearchStrings ? (
                    tagSearchStrings.map((str) => (
                      <SearchStringItem
                        key={str.search_string_id}
                        string_id={str.search_string_id}
                        value={str.search_string}
                        tag_id={tag.tag_id}
                      />
                    ))
                  ) : (
                    <div className="search-strings-container-list_no-results">
                      <h4>No search strings found</h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewTag
