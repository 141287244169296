import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

export const EmptyLayout = ({ children }) => {
  const { isAuthenticated } = useAuth0()
  if (isAuthenticated) {
    return <Redirect to="/" />
  } else {
    return children
  }
}

EmptyLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
