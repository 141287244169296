import React, { useState } from 'react'
import PropTypes from 'prop-types'

export const AddRoleForm = ({ onUpdate, onCancel }) => {
  const [title, setTitle] = useState('')

  const handleOnUpdate = () => {
    onUpdate({ title })
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-3">
        <label className="block">
          <span className="text-gray-700">Role Name</span>
          <input
            type="text"
            className="block w-full input"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </label>
      </div>
      <div className="modal-action__container">
        <button
          type="button"
          className="button-update"
          onClick={handleOnUpdate}
        >
          Add New
        </button>
        <button type="button" className="button-cancel" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </>
  )
}

AddRoleForm.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}
