import { useEffect, useState } from 'react'
import { useStateContext } from '../../Store/Store'
import { FetchDolUsedHashtags } from '../../dispatchers'
import HashtagsUsedProps from './interface'
import Table from '../Table'
import './styles.scss'

const HashtagsUsed = ({ title, userId, limit = '10', offset = '0' }: HashtagsUsedProps) => {
  const columns = ['Name', 'Count']

  const { state, dispatch } = useStateContext()
  const { dolHashtagsUsed, appConfig, user } = state
  const { global } = appConfig

  useEffect(() => {
    if (user.token) FetchDolUsedHashtags(dispatch, userId, user.token, `?limit=${limit}&offset=${offset}`)
  }, [dispatch, userId, user.token, offset, limit])

  const convertToHashtagLinks = (array) => {
    return array
      ? array.map((element) => {
          return {
            item: (
              <a
                href={`${global.twitterUrl}hashtag/${element.item}`}
                target="_blank"
                rel="noreferrer"
              >
                {`#${element.item}`}
              </a>
            ),
            count: 
            (<p className='count'>{element.count}</p>),
          }
        })
      : []
  }

  const convertedData = convertToHashtagLinks(dolHashtagsUsed)

  return (
    <div className="hashtags-used">
      <h3>{title}</h3>
      <hr/>
      <Table columns={columns} data={convertedData} />
    </div>
  )
}

export default HashtagsUsed
