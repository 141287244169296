import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

export const PermissionForm = ({ values, onUpdate, onCancel }) => {
  const [title, setTitle] = useState('')
  const [is_active, setActive] = useState(false)

  useEffect(() => {
    setTitle(values?.title)
    setActive(values?.is_active)
  }, [values])

  return (
    <>
      <div className="grid grid-cols-1 gap-6">
        <label className="block">
          <span className="text-gray-700">Permission Name</span>
          <input
            type="text"
            className="block w-full input"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </label>

        <label className="checkbox-label">
          <input
            type="checkbox"
            className="checkbox-input"
            checked={is_active}
            onChange={(e) => setActive(e.target.checked)}
          />
          <span className="ml-2">Active</span>
        </label>
      </div>
      <div className="modal-action__container">
        <button
          type="button"
          className="button-update"
          onClick={() => onUpdate({ title, is_active })}
        >
          Update
        </button>
        <button type="button" className="button-cancel" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </>
  )
}

PermissionForm.propTypes = {
  values: PropTypes.shape({
    title: PropTypes.string,
    isActive: PropTypes.bool,
  }),
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}
