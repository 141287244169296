import { NavigationInterface } from './interface'
import { LinkItemChildrenType } from '../../Types'

const Navigation = ({ navigation }: NavigationInterface) => {
  const buildLinks = (linkList: LinkItemChildrenType) => {
    const links = []

    for (const [key, value] of Object.entries(linkList)) {
      if (value.isNavigation) {
        links.push(
          <li key={key}>
            <a href={value.path}>{value.title}</a>
            {value.children && <ul>{buildLinks(value.children)}</ul>}
          </li>
        )
      }
    }

    return links
  }

  return <ul>{buildLinks(navigation)}</ul>
}

export default Navigation
