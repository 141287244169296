/**
 * List of values that are used to create helper classes for (in pixels)
 * e.g. p0 - padding: 0px
 */
const values = [
  '0',
  '5',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '40',
  '45',
  '50',
]

/**
 * Loop through list of values and generate classes
 * @return Object - object of generated classes
 */
const prepareClasses = () => {
  let output = {}

  const classes = values.map((v) => {
    const value = `${v}px`
    return {
      [`p${v}`]: { padding: value },
      [`pb${v}`]: { paddingBottom: value },
      [`pt${v}`]: { paddingTop: value },
      [`pr${v}`]: { paddingRight: value },
      [`pl${v}`]: { paddingLeft: value },
    }
  })

  Object.keys(classes).map((key) => {
    let obj = classes[key]
    output = {
      ...output,
      ...obj,
    }
    return {}
  })

  return output
}

/**
 * Export object of paddings
 */
export const paddings = {
  p25p5: {
    padding: '25.5px',
  },
  ...prepareClasses(),
}
