import { useAuth0 } from '@auth0/auth0-react'
import { useStateContext } from '../../Store/Store'

const LoginPage = () => {
  const { state } = useStateContext()
  const { loginWithRedirect } = useAuth0()
  const { redirectUrl } = state

  return loginWithRedirect({appState : {returnTo : redirectUrl}})
}

export default LoginPage
