import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'fit-content',
    margin: '0 auto',
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}))

export const BasicPagination = ({
  page,
  totalPageCount,
  searchResults,
  setPageNumber,
  setLastSeenProfileID
}) => {
  const classes = useStyles()
  const { dmt_gateway_search_profiles_clustered, dmt_gateway_therapy_state_search_clustered } = searchResults

  const onPaginationChange = (e, val) => {
    setPageNumber(val)
    if (dmt_gateway_search_profiles_clustered) {
      setLastSeenProfileID(dmt_gateway_search_profiles_clustered.page_directory[
          val - 1
      ].preceding_id )
    }
    else if (dmt_gateway_therapy_state_search_clustered) {
      setLastSeenProfileID(dmt_gateway_therapy_state_search_clustered.page_directory[
        val - 1
      ].preceding_id)
    }
  }

  if (!totalPageCount) return null

  return (
    <div className={classes.root}>
      <Pagination
        page={page}
        count={totalPageCount}
        onChange={onPaginationChange}
        data-cy="basic-pagination"
      />
    </div>
  )
}

BasicPagination.propTypes = {
  totalPageCount: PropTypes.number,
}

export default BasicPagination
