import React from 'react'
import { TableHeaderType } from './interface'

const TableHead = ({ columns }: TableHeaderType) => {
  return (
    <thead>
      <tr>
        {columns?.map((column) => (
          <th key={column}>{column}</th>
        ))}
      </tr>
    </thead>
  )
}

export default TableHead
