import React from 'react'

const PageNotFoundMessage = () => (
  <div>
    <h1>404</h1>
    <h2>Page not found</h2>
    <p>Are you sure you typed the correct URL?</p>
  </div>
)

export default PageNotFoundMessage
