import axios from 'axios'
import fileDownload from 'js-file-download'
import AppConfig from '../config/AppConfig';
import { setApiHeader } from '../Helpers';

export const FetchTopicDolDownload = async(dispatch, id, filename, token) => {
	const { global } = AppConfig;

	return axios
		.get(`${global.apiUrl}/topic/${id}/dols/excel`,
			{ responseType: 'blob', headers: setApiHeader(token) },
		).then((res) => {
			fileDownload(res.data, filename)
		}
	)
}
