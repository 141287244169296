import axios from 'axios'
import AppConfig from '../config/AppConfig';
import { setApiHeader } from '../Helpers';
import { ActionTypesEnum } from '../Enums'

export const UserSearch = async(dispatch, search, token) => {
  const { global } = AppConfig;

  return axios
    .post(`${global.apiUrl}/search/users`, { search }, { headers: setApiHeader(token) })
    .then((result) => {
      dispatch({ type: ActionTypesEnum.USER_SEARCH, payload: result.data && result.data.length > 0 ? result.data : null })
    })
    .catch((error) => {
      dispatch({
        type: 'notification',
        payload: { message: error, type: 'error' },
      })
      console.error(`Could not get search data: ${error}`)
    })
}
