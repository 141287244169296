import TableBodyProps from './interface'

const TableBody = ({ data }: TableBodyProps) => {
  const rowDataCells = (rowData) =>
    Object.values(rowData).map((item, index) => 
      <td key={index}>
        {item}
      </td>
    )

  return (
    <tbody>
      {data.map((rowData, i) => (
        <tr key={`row-${i}`}>
          {rowDataCells(rowData)}
        </tr>
      ))}
    </tbody>
  )
}

export default TableBody
