import axios from 'axios';
import AppConfig from '../config/AppConfig';
import { setApiHeader } from '../Helpers';

export const DeleteTagSearchString = async(dispatch, search_string_id, tag_id, token) => {
  const { global } = AppConfig;

  return axios
    .delete(`${global.apiUrl}/tag/${tag_id}/search_strings/${search_string_id}`, {headers: setApiHeader(token) })
    .then((res) => {
      dispatch({ type: 'delete_tag_search_string', payload: res.data })
      window.location.pathname = `/tag/${tag_id}`
      dispatch({
        type: 'notification',
        payload: {
          message: 'Search string was removed successfully',
          type: 'success',
        },
      })
    })
    .catch((err) => {
      dispatch({
        type: 'notification',
        payload: { message: err, type: 'error' },
      })
      console.error(
        'error when attempting to remove delete search string: ',
        err
      )
      dispatch({ type: 'delete_tag_search_string', payload: {} })
    })
}
