import React from 'react'
import PropTypes from 'prop-types'
import { useStateMachine } from 'little-state-machine'
import CaptureNavbar from '../navbars/capture-navbar'

export const StepperLayout = ({ title, children, action }) => {
  const { state } = useStateMachine(action)

  return (
    <div className="container">
      <CaptureNavbar />

      <div className="flex items-start justify-center gap-4 mt-8">
        <div className="w-full p-5 mx-auto bg-white shadow-xl">
          {title && <div className="mb-4 title">{title}</div>}
          {children}
        </div>
        <div className="w-full p-5 mx-auto bg-white shadow-xl">
          <div className="subtitle">Debug info:</div>
          <pre>{JSON.stringify(state, null, 2)}</pre>
        </div>
      </div>
    </div>
  )
}

StepperLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  action: PropTypes.func,
}

export default StepperLayout
