import { createElement, ReactNode } from 'react'

import './Text.css'

const TAGS = {
  CAPTION: 'caption',
  LABEL: 'label',
  PARAGRAPH: 'p',
}

type Props = {
  align?: 'center' | 'left' | 'right'
  children: ReactNode
  type?: 'caption' | 'label' | 'p'
}

export const Text = ({ align, children, type }: Props) => {
  const element = type && type === TAGS.LABEL ? 'label' : 'p'
  const classes = ['text']
  if (type !== TAGS.PARAGRAPH) {
    // tslint:disable-next-line
    classes.push(type!)
  }
  classes.push(!align ? 'left' : align)
  return createElement(element, { className: classes.join(' ') }, children)
}

export default Text
