import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_ROLES } from '@visformatics/queries'
import PropTypes from 'prop-types'

const setInitialRoles = (data, simplifiedRoles) => {
  let initialRoles = {}
  data?.insights_service_roles.map(({ id }) => {
    initialRoles[id] = simplifiedRoles.includes(id) || false
    return false
  })
  return initialRoles
}

export const UserForm = ({ values, onUpdate, onCancel, simplifiedRoles }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [roles, setRoles] = useState({})

  const [getRoles, { data }] = useLazyQuery(GET_ROLES)

  // Get list of all roles
  useEffect(() => {
    getRoles()
  }, [getRoles])

  // Initialise default values to form inputs
  useEffect(() => {
    setName(values?.name)
    setEmail(values?.email)
    setRoles(setInitialRoles(data, simplifiedRoles))
  }, [values, data, simplifiedRoles])

  const handleOnUpdate = () => {
    const arr = Object.keys(roles)
    let result = []
    arr.map((role) => {
      result = [
        ...result,
        {
          role_id: role,
          include_to_user: roles[role],
        },
      ]
      return result
    })
    const detachRoles = result.filter(
      ({ include_to_user }) => include_to_user === false
    )
    const attachRoles = result.filter(
      ({ include_to_user }) => include_to_user === true
    )

    onUpdate({ name, email, detachRoles, attachRoles })
  }

  const handleRoles = (event) => {
    setRoles({
      ...roles,
      [event.target.name]: event.target.checked,
    })
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-3">
        <label className="block">
          <span className="text-gray-700">User Name</span>
          <input
            disabled
            type="text"
            className="block w-full bg-gray-100 cursor-not-allowed input"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>

        <label className="block">
          <span className="text-gray-700">Email</span>
          <input
            disabled
            type="text"
            className="block w-full bg-gray-100 cursor-not-allowed input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>

        <label className="block mt-4">Attach roles:</label>
        {data?.insights_service_roles?.map(({ id, title }, index) => (
          <label
            className="inline-flex items-center p-2 cursor-pointer hover:bg-gray-100"
            key={index}
          >
            <input
              type="checkbox"
              className="border-gray-300 rounded shadow-sm text-secondary-600 focus:border-secondary-300 focus:ring focus:ring-offset-0 focus:ring-secondary-200 focus:ring-opacity-50"
              checked={roles[id] || false}
              name={id}
              onChange={handleRoles}
            />
            <span className="ml-2">{title}</span>
          </label>
        ))}
      </div>
      <div className="modal-action__container">
        <button
          type="button"
          className="button-update"
          onClick={handleOnUpdate}
        >
          Update
        </button>
        <button type="button" className="button-cancel" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </>
  )
}

UserForm.propTypes = {
  values: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  simplifiedRoles: PropTypes.array,
}
