import React from 'react'
import PropTypes from 'prop-types'

export const SettingsForm = ({
  dataColumns,
  handleDataColumns,
  handleOnUpdate,
}) => {
  return (
    <>
      <div className="relative grid grid-cols-1 gap-3 overflow-y-auto max-h-80 scrollbar scrollbar-thin scrollbar-track-primary-100 scrollbar-thumb-primary-600">
        {dataColumns.map(({ key, label, is_active }, index) => (
          <label
            className="inline-flex items-center p-2 cursor-pointer hover:bg-gray-100"
            key={index}
          >
            <input
              type="checkbox"
              className="border-gray-300 rounded shadow-sm text-secondary-600 focus:border-secondary-300 focus:ring focus:ring-offset-0 focus:ring-secondary-200 focus:ring-opacity-50"
              checked={is_active}
              name={key}
              onChange={(event) => handleDataColumns(key, event)}
            />
            <span className="ml-2">
              {label}{' '}
              {!is_active ? (
                <span className="text-red-500">(inactive)</span>
              ) : null}
            </span>
          </label>
        ))}
      </div>

      <div className="modal-action__container">
        <button
          type="button"
          className="button-update"
          onClick={handleOnUpdate}
        >
          Ok
        </button>
      </div>
    </>
  )
}

SettingsForm.propTypes = {
  dataColumns: PropTypes.array,
  handleDataColumns: PropTypes.func,
  handleOnUpdate: PropTypes.func,
}
