import ReactDOM from 'react-dom'
import AuthProvider from './Providers'
import { StateProvider } from './Store/Store'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { DefaultLayout } from './layouts/default/DefaultLayouts'
import UserSupport from './userSupport'
import IntercomCustomBtn from './userSupport/IntercomCustomBtn'
import Routes from './routes'

import './styles.scss'

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <DefaultLayout>
          <Component {...props} />
        </DefaultLayout>
      )}
    />
  )
}

const PublicRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />
}

const App = ({ routes }) => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <StateProvider>
          <UserSupport>
            <IntercomCustomBtn/>
          </UserSupport>
          <Switch>
            {routes.map((route, index) => {
              const CustomRoute = route.protected ? PrivateRoute : PublicRoute
              return <CustomRoute {...route} key={index} />
            })}
          </Switch>
        </StateProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

ReactDOM.render(<App routes={Routes} />, document.getElementById('root'))
