import React from 'react'
import SelectProps from './interface'
import './style.scss'

const Select = ({
  title,
  options,
  onChange,
  disabled,
  defaultValue,
}: SelectProps) => {
  return (
    <div className="select">
      {title ?? <label className="select-title">{title}</label>}
      <select
        onChange={onChange}
        disabled={disabled}
        defaultValue={defaultValue}
      >
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default Select
