import React from 'react'
import { FlatButton } from '@visformatics/components'
import Toggle from '../../../components/Toggle'
import './styles.scss'

const TopicListItem = ({ topic, editString, viewString, activeString, updateTopicAction }) => {

  const handleViewOnClick = () => {
    return (window.location.pathname = `/topic/${topic.topic_id}`)
  }

  const handleEditOnClick = () => {
    return (window.location.pathname = `/topic/${topic.topic_id}/edit`)
  }

  const handleToggle = () => {
    const topicObject = {
      topic_id: topic.topic_id,
      topic_text: topic.topic_text,
      description: topic.description,
      topic_definition: {
        hashtags: topic.topic_definition.hashtags,
        search_string: topic.topic_definition.search_string,
      },
      active: !topic.active,
    }
    updateTopicAction(topicObject);
  }

  return (
    <div className="topic-item">
      <div className="topic-item-container">
        <div className="topic-item-container_main">
          <h2>{topic.topic_text ?? 'Topic'}</h2>
          <p>{topic.description ?? 'No description provided'}</p>
        </div>
        <div className="topic-item-container-wrapper">
          <div className="topic-item-container-wrapper_toggle">
            <label htmlFor="active">{activeString}</label>
            <Toggle name="active" onChange={handleToggle} isChecked={topic.active} />
          </div>
          <div className="topic-item-container_actions">
            <FlatButton click={handleViewOnClick}>{viewString}</FlatButton>
            <FlatButton click={handleEditOnClick}>{editString}</FlatButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopicListItem
