import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'

const { NX_AUTH_DOMAIN, NX_AUTH_CLIENT_ID, NX_AUTH_AUDIENCE } = process.env

export const Auth = ({ children }) => {
  return (
    <Auth0Provider
      domain={NX_AUTH_DOMAIN}
      clientId={NX_AUTH_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={NX_AUTH_AUDIENCE}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  )
}
