import { useState } from 'react';
import {IconProps} from './interface'
import twitterVerifiedUser from '../../assets/twitterVerifiedUserOutlined.svg'
import Modal from '../Modal'
import AppConfig from '../../config/AppConfig';

const VerifiedIcon = ({
  source,
  alt = 'Twitter Verified',
  width = '24px',
  height = '24px',
}: IconProps) => {
  const { global } = AppConfig;
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }
  
  return (
    <>
      <button className='verified-icon-modal' onClick={openModal}>
        <img src={`${source ?? twitterVerifiedUser}`}
          alt={alt}
          width={width}
          height={height}
          className='verified-icon'
        />
      </button>

      {showModal && (
        <Modal
          title='Verified account'
          titleIcon={twitterVerifiedUser}
          textContent={global.verifiedIconDescription}
          onCancel={closeModal}
          modalCloseText='OK'
          closeable={false}
        />
      )}
    </>
  )
}

export default VerifiedIcon
