import React from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import PropTypes from 'prop-types'
import './loader.scss'

export function Loader({ loading, overlay }) {
  return (
    <div className={`loader-container${overlay ? ' bg-white opacity-75' : ''}`}>
      <PulseLoader color="#000000" loading={loading} size={15} />
    </div>
  )
}

Loader.defaultProps = {
  overlay: false,
}

Loader.propTypes = {
  loading: PropTypes.bool,
  overlay: PropTypes.bool,
}

export default Loader
