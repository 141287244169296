import axios from 'axios'
import AppConfig from '../config/AppConfig';
import { setApiHeader } from '../Helpers';

const createDispatch = (dispatch, type, payload) => dispatch({ type, payload });

const digestHashtags = (dispatch, url, token) => {
  return axios
    .get(url, { headers: setApiHeader(token) })
    .then((res) =>
      createDispatch(dispatch, 'fetch_dol_used_hashtags', res.data)
    )
    .catch((err) => {
      createDispatch(dispatch, 'notification', { message: err, type: 'error' })
      createDispatch(dispatch, 'fetch_dol_used_hashtags', [])
    })
}

const digestCloudHashtags = (dispatch, url, token) => {
  return axios
    .get(url, { headers: setApiHeader(token) })
    .then((res) =>
      createDispatch(dispatch, 'fetch_dol_used_cloud_hashtags', res.data)
    )
    .catch((err) => {
      createDispatch(dispatch, 'notification', { message: err, type: 'error' })
      createDispatch(dispatch, 'fetch_dol_used_cloud_hashtags', [])
    })
}

export const FetchDolUsedHashtags = async (dispatch, id, token, options?) => {
  const { global } = AppConfig
  const url = `${global.apiUrl}/user/${id}/hashtags`
  const urlWithOptions = `${url}${options}`

  return options
    ? digestHashtags(dispatch, urlWithOptions, token)
    : digestCloudHashtags(dispatch, url, token)
}
