import { useState } from 'react'
import Select from '../Select'
import { useStateContext } from '../../Store/Store'
import HashtagsUsed from '../HashtagsUsed'
import './style.scss'

const HashtagsUsedPagination = ({ children, userId }) => {
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)

  const { state, dispatch } = useStateContext()
  const { dolHashtagsUsed, appConfig } = state
  const { global } = appConfig

  const checkConstraints = dolHashtagsUsed?.length < limit

  const limitOptions = [
    { value: 10, text: '10 entries' },
    { value: 15, text: '15 entries' },
    { value: 20, text: '20 entries' },
    { value: 25, text: '25 entries' },
    { value: 50, text: '50 entries' },
  ]

  const handleLimitChange = (e) => {
    const { value } = e.target
    setLimit(value)
  }

  const showNextPageAction = () => {
    setOffset((prevState) => prevState + limit)
  }

  const showPreviousPageAction = () => {
    if (offset > limit) {
      setOffset((prevState) => prevState - limit)
    }
  }

  const showFirstPageAction = () => {
    setOffset(0)
  }

  return (
    <div className='pagination-options'>
      <HashtagsUsed
        title={global.hashtagsUsedTitle}
        userId={userId}
        offset={offset}
        limit={limit}
      />
      <div className="pagination">
        <button disabled={offset === 0} onClick={showFirstPageAction}>
          &lt;&lt;&nbsp;First
        </button>
        <button disabled={offset === 0} onClick={showPreviousPageAction}>
          &lt;&nbsp;Previous
        </button>
        <button disabled={checkConstraints} onClick={showNextPageAction}>
          Next&nbsp;&gt;
        </button>
      </div>
      <Select
        title="Set limit:"
        defaultValue={limit}
        options={limitOptions}
        onChange={handleLimitChange}
      />
    </div>
  )
}

export default HashtagsUsedPagination
