import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/client'
import {
  GET_QUESTION_HEADINGS,
  GET_QUESTION_TYPES,
} from '@visformatics/queries'

const ALLOWED_MULTIPLE_CHOICE = ['single-select', 'multi-select', 'radio']

export const AddEditQuestionForm = ({
  isEdit,
  values,
  onUpdate,
  onCancel,
  defaultPosition,
}) => {
  const [id, setId] = useState(null)
  const [parentId, setParentId] = useState(null)
  const [title, setTitle] = useState('')
  const [position, setPosition] = useState(0)
  const [questionType, setQuestionType] = useState('')
  const [questionHeading, setQuestionHeading] = useState('')
  const [allowOther, setAllowOther] = useState(false)
  const [taggable, setTaggable] = useState(true)
  const [isActive, setIsActive] = useState(false)
  const [choices, setChoices] = useState([''])

  const [getQuestionTypes, { data: questionTypes }] =
    useLazyQuery(GET_QUESTION_TYPES)
  const [getQuestionHeadings, { data: questionHeadings }] = useLazyQuery(
    GET_QUESTION_HEADINGS
  )

  // Initialise default values for edit
  useEffect(() => {
    setId(values?.id || null)
    setParentId(values?.parent_id || null)
    setTitle(values?.title)
    setPosition(values?.position || defaultPosition || 0)
    setQuestionType(values?.question_type_id || '')
    setQuestionHeading(values?.heading_id || '')
    setAllowOther(values?.allow_other || false)
    setTaggable(values?.taggable || false)
    setIsActive(values?.is_active || false)
    setChoices(values?.answers?.map(({ title }) => title) || [''])
  }, [values, defaultPosition])

  // Get list of all question types
  useEffect(() => {
    getQuestionTypes()
    getQuestionHeadings()
  }, [getQuestionTypes, getQuestionHeadings, setQuestionType, questionTypes])

  // Display multiple choices fields or not
  const allowedChoiceIds =
    questionTypes?.insights_service_question_type
      .filter((type) => ALLOWED_MULTIPLE_CHOICE.includes(type.title))
      .map(({ id }) => id) || []
  const choicesAreAllowed = allowedChoiceIds.includes(questionType)

  // On add / edit question
  const handleOnUpdate = () => {
    onUpdate({
      ...(id ? { id } : {}),
      title,
      position,
      question_type_id: Number(questionType),
      ...(questionHeading ? { heading_id: Number(questionHeading) } : {}),
      ...(parentId ? { parent_id: parentId } : {}),
      allow_other: allowOther,
      is_active: isActive,
      ...(choicesAreAllowed
        ? {
            answers: {
              data: [
                ...choices.map((choice, index) => {
                  return { is_predefined: true, position: index, title: choice }
                }),
              ],
            },
          }
        : { taggable }),
    })
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-3">
        {id && (
          <div className="mt-2 text-sm font-bold text-primary-600">
            QUESTION-{id}
          </div>
        )}

        <label className="block">
          <span className="text-gray-700">Title</span>
          <textarea
            type="text"
            className="block w-full input"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            rows={3}
          />
        </label>

        {questionHeadings?.insights_service_question_headings?.length && (
          <label className="block">
            <span className="text-gray-700">Heading</span>
            <select
              className="select"
              value={questionHeading}
              onChange={(e) => setQuestionHeading(e.target.value)}
            >
              <option value=""></option>
              {questionHeadings?.insights_service_question_headings.map(
                ({ id, title }, index) => (
                  <option key={index} value={id}>
                    {title}
                  </option>
                )
              )}
            </select>
          </label>
        )}

        {questionTypes?.insights_service_question_type?.length && (
          <label className="block">
            <span className="text-gray-700">Type</span>
            <select
              className="select"
              value={questionType}
              onChange={(e) => setQuestionType(Number(e.target.value))}
            >
              <option value=""></option>
              {questionTypes?.insights_service_question_type?.map(
                ({ id, title }, index) => (
                  <option key={index} value={id}>
                    {title}
                  </option>
                )
              )}
            </select>
          </label>
        )}

        {choicesAreAllowed && (
          <div className="relative w-full">
            <span className="flex items-center justify-between text-gray-700">
              Multiselect options{' '}
              <span>
                <span
                  className="px-2 text-right bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer hover:bg-gray-50"
                  onClick={() => setChoices([...choices, ''])}
                >
                  +
                </span>

                {choices.length > 1 ? (
                  <span
                    className="px-2 text-right bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer hover:bg-gray-50"
                    onClick={() =>
                      choices.length > 1 &&
                      setChoices([...choices.slice(0, -1)])
                    }
                  >
                    -
                  </span>
                ) : null}
              </span>
            </span>
            <div className="relative overflow-y-auto max-h-40 scrollbar scrollbar-thin scrollbar-track-primary-100 scrollbar-thumb-primary-600">
              <label className="block">
                {choices.map((choice, index) => (
                  <input
                    key={index}
                    name={index}
                    type="text"
                    className="block w-full input"
                    value={choices[index]}
                    onChange={(e) => {
                      let temp = [...choices]
                      temp[index] = e.target.value
                      setChoices([...temp])
                    }}
                  />
                ))}
              </label>
            </div>
          </div>
        )}

        <label className="block">
          <span className="text-gray-700">Position</span>
          <input
            type="number"
            className="block w-full input"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          />
        </label>

        <label className="block">
          <span className="text-gray-700">Parent ID</span>
          <input
            type="text"
            className="block w-full input"
            value={parentId}
            onChange={(e) => setParentId(e.target.value)}
          />
        </label>

        <label className="checkbox-label">
          <input
            type="checkbox"
            className="checkbox-input"
            checked={allowOther}
            onChange={(e) => setAllowOther(e.target.checked)}
          />
          <span className="ml-2">Allow Other</span>
        </label>

        {!choicesAreAllowed ? (
          <label className="checkbox-label">
            <input
              type="checkbox"
              className="checkbox-input"
              checked={taggable}
              onChange={(e) => setTaggable(e.target.checked)}
            />
            <span className="ml-2">Taggable</span>
          </label>
        ) : null}

        {isEdit && (
          <label className="checkbox-label">
            <input
              type="checkbox"
              className="checkbox-input"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
            />
            <span className="ml-2">Active</span>
          </label>
        )}
      </div>

      <div className="modal-action__container">
        {!isEdit ? (
          <button
            type="button"
            className="button-update"
            onClick={handleOnUpdate}
          >
            {isEdit ? 'Edit' : 'Add'} Question
          </button>
        ) : (
          <span className="self-center ml-2 text-orange-500">
            Edit is currently unavailable
          </span>
        )}
        <button type="button" className="button-cancel" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </>
  )
}

AddEditQuestionForm.defaultProps = {
  isEdit: false,
}

AddEditQuestionForm.propTypes = {
  isEdit: PropTypes.bool,
  values: PropTypes.object,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  defaultPosition: PropTypes.number,
}
