import MenuProps from './interface'
import {ReactComponent as CloseIcon} from '../../assets/close-menu-icon.svg'
import {ReactComponent as HamburgerIcon} from '../../assets/hamburger-icon.svg'
import './style.scss'

const Menu = ({ isSelected, onClick }: MenuProps) => {
  return (
    <button
      type='button'
      onClick={onClick}
      className={`menu-button ${isSelected ? 'selected' : ''}`}
    >
      {isSelected ? <CloseIcon /> : <HamburgerIcon /> }
    </button>
    
  )
}

export default Menu
