import React from 'react'

import { Text } from '../text/Text'

import './Badge.css'

type Props = {
  children: string
  type?:
    | 'danger'
    | 'dark'
    | 'info'
    | 'light'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
}

export const Badge = ({ children, type }: Props) => {
  return (
    <div className={`badge ${type ? 'badge__' + type : 'badge__primary'}`}>
      <Text type="caption">{children}</Text>
    </div>
  )
}
