import { gql } from '@apollo/client'

/**
 * Get a list of insights reporting
 */
export const GET_INSIGHTS_REPORTING = gql`
  query GetInsightsReporting($filters: [insights_service_reporting_bool_exp]) {
    insights_service_reporting(where: { _and: $filters }) {
      insight_id
      heading
      question
      answer
      actions
      therapy_area
      practice_setting
      primary_role
      which_products
      recognition_level
      categorize_insight
      disease_state
      kol
      user
      interview_start_time
      created_at
      heading_id
    }
  }
`

/**
 * Get a list of us regions
 */
export const GET_REGIONS = gql`
  query GetRegions {
    insights_service_us_state_iso2_lookup {
      id
      iso2
      state
    }
  }
`

/**
 * Mutation that adds user from auth0 metadata
 */
export const ADD_USER = gql`
  mutation AddUser(
    $name: String
    $email: String
    $updated_at: timestamptz!
    $created_at: timestamptz!
    $role_id: Int
    $options: String
  ) {
    insert_insights_service_users(
      objects: {
        name: $name
        email: $email
        updated_at: $updated_at
        created_at: $created_at
        users_roles: { data: { role_id: $role_id } }
        user_settings: { data: { options: $options } }
      }
    ) {
      affected_rows
      returning {
        id
        name
        email
        users_roles {
          role {
            id
            title
            role_permissions(
              where: { permission: { is_active: { _eq: true } } }
            ) {
              permission {
                id
                title
              }
            }
          }
        }
        user_settings(distinct_on: user_id) {
          id
          options
          user_id
        }
      }
    }
  }
`

/**
 * Query that fetches users by email address
 */
export const GET_USER = gql`
  query GetUser($email: String) {
    insights_service_users(where: { email: { _eq: $email } }, distinct_on: id) {
      id
      name
      email
      users_roles(distinct_on: role_id) {
        role {
          id
          title
          role_permissions(
            where: { permission: { is_active: { _eq: true } } }
            distinct_on: permission_id
          ) {
            permission {
              id
              title
            }
          }
        }
      }
      user_settings(distinct_on: user_id) {
        id
        options
        user_id
      }
    }
    insights_service_roles {
      id
      title
    }
  }
`

/**
 * Mutation that updates user settings
 */
export const UPDATE_USER_SETTINGS = gql`
  mutation UpdateUserSettings($id: Int!, $options: String) {
    update_insights_service_user_settings(
      where: { id: { _eq: $id } }
      _set: { options: $options }
    ) {
      affected_rows
      returning {
        id
        options
        user_id
      }
    }
  }
`

/**
 * Query that fetches dmt_gateway_search_profiles by person name
 */
export const SEARCH_PROFILES = gql`
  query GetByAuthorNamePartial($person_name: String!) {
    dmt_gateway_search_profiles(personName: $person_name) {
      takeda_id
      person_name
      disease_area
      source
      city
      country
      region_name
      profile_image
    }
  }
`

/**
 * Query that fetches all the questions for a specified therapy area
 * Note: We don't need to get none predefined answers for questions
 * We only have predefined answers for `multi-select`
 */

export const GET_QUESTIONS_FOR_TA = gql`
  query QetQuestions($therapy_area: String) {
    insights_service_questions(
      order_by: { position: asc }
      where: {
        _or: [
          { therapy_area: { _eq: "" } }
          { therapy_area: { _eq: $therapy_area } }
        ]
        _and: { is_active: { _eq: true } }
      }
    ) {
      id
      allow_other
      therapy_area
      title
      question_type {
        title
      }
      answers(
        order_by: { position: asc }
        where: {
          question: {
            question_type: {
              _and: { title: { _in: ["multi-select", "single-select"] } }
            }
          }
          is_predefined: { _eq: true }
        }
      ) {
        title
        is_predefined
        id
      }
      question_heading {
        title
      }
    }
  }
`

/**
 * Get a list of profiles from dmt_gateway
 */
export const GET_PROFILES_BY_NAME = gql`
  query GET_PROFILES_BY_NAME($person_name: String!) {
    dmt_gateway_search_profiles(personName: $person_name) {
      address
      city
      coi
      coi_extended
      country
      disease_area
      display_name
      email
      impact_factor
      is_cited_author
      is_copied
      is_deceased
      is_guideline_author
      is_hcp
      last_updated_at
      last_updated_by
      latitude
      linkedin_url
      longitude
      notes
      organisation
      person_name
      primary_disease_area
      profile_image
      region_name
      role
      source
      takeda_id
      twitter_username
      website
    }
  }
`
