import { createContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import service from './service'
import { useAuth0 } from '@auth0/auth0-react'
import './intercom.css'

const api = service.create({
  appId: process.env.NX_USER_SUPPORT_APP_ID,
})

export const UserSupportContext = createContext({})

export const UserSupport = ({ children }) => {
  let history = useHistory()
  const { isAuthenticated, user } = useAuth0()

  useEffect(() => {
    api.init()
    api.configure({
      custom_launcher_selector: '#support_launcher',
      hide_default_launcher: true,
      alignment: 'right',
    })
    return history.listen(() => {
      api.update()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    (async function () {
      if (isAuthenticated) {
        const details = {
          name: user?.name,
          email: user?.email,
          user_id: user?.sub,
        }
        api.update(details)
      }
    })()
  }, [isAuthenticated, user])

  return (
    <UserSupportContext.Provider value={api}>
      {children}
    </UserSupportContext.Provider>
  )
}

export default UserSupport
