import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_PERMISSIONS } from '@visformatics/queries'
import PropTypes from 'prop-types'

const setInitialPermissions = (data, simplifiedRoles) => {
  let initialPermissions = {}
  data?.insights_service_permissions.map(({ id }) => {
    initialPermissions[id] = simplifiedRoles.includes(id) || false
    return false
  })
  return initialPermissions
}

export const RoleForm = ({ values, onUpdate, onCancel, simplifiedRoles }) => {
  const [title, setTitle] = useState('')
  const [permissions, setPermissions] = useState({})

  const [getPermissions, { data }] = useLazyQuery(GET_PERMISSIONS)

  // Get list of all permissions
  useEffect(() => {
    getPermissions()
  }, [getPermissions])

  // Initialise default values to form inputs
  useEffect(() => {
    setTitle(values?.title)
    setPermissions(setInitialPermissions(data, simplifiedRoles))
  }, [values, simplifiedRoles, data])

  const handlePermissions = (event) => {
    setPermissions({
      ...permissions,
      [event.target.name]: event.target.checked,
    })
  }

  const handleOnUpdate = () => {
    const arr = Object.keys(permissions)
    let result = []
    arr.map((perm) => {
      result = [
        ...result,
        {
          permission_id: perm,
          include_to_role: permissions[perm],
        },
      ]
      return result
    })
    const detachPermissions = result.filter(
      ({ include_to_role }) => include_to_role === false
    )
    const attachPermissions = result.filter(
      ({ include_to_role }) => include_to_role === true
    )
    onUpdate({ title, detachPermissions, attachPermissions })
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-3">
        <label className="block">
          <span className="text-gray-700">Role Name</span>
          <input
            type="text"
            className="block w-full input"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </label>
      </div>
      <label className="block mt-4">Attach permissions:</label>
      <div className="relative grid grid-cols-1 gap-3 overflow-y-auto max-h-80 scrollbar scrollbar-thin scrollbar-track-primary-100 scrollbar-thumb-primary-600">
        {data?.insights_service_permissions?.map(
          ({ id, title, is_active }, index) => (
            <label
              className="inline-flex items-center p-2 cursor-pointer hover:bg-gray-100"
              key={index}
            >
              <input
                type="checkbox"
                className="border-gray-300 rounded shadow-sm text-secondary-600 focus:border-secondary-300 focus:ring focus:ring-offset-0 focus:ring-secondary-200 focus:ring-opacity-50"
                checked={permissions[id] || false}
                name={id}
                onChange={handlePermissions}
              />
              <span className="ml-2">
                {title}{' '}
                {!is_active ? (
                  <span className="text-red-500">(inactive)</span>
                ) : null}
              </span>
            </label>
          )
        )}
      </div>

      <div className="modal-action__container">
        <button
          type="button"
          className="button-update"
          onClick={handleOnUpdate}
        >
          Update
        </button>
        <button type="button" className="button-cancel" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </>
  )
}

RoleForm.propTypes = {
  values: PropTypes.shape({
    title: PropTypes.string,
  }),
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  simplifiedRoles: PropTypes.array,
}
