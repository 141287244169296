import { useEffect } from 'react';
import {
  FetchDolAssociatedTags,
  FetchDolAssociatedTopics
} from '../../dispatchers';
import {useParams} from 'react-router-dom'
import { DolProfileInterface } from '../../Interfaces';
import { useStateContext } from '../../Store/Store'
import HashtagsUsed from '../HashtagsUsed';
import { profileImageUrl } from '../../Helpers';
import InfluencerProfileCard from '../InfluencerProfileCard';
import AssociatedTags from '../Profile/AssociatedTags';
import AssociatedTopics from '../Profile/AssociatedTopics';
import './Styles.scss';

const MiniProfile = ({profile, hashtagsUsedTitle }: DolProfileInterface) => {
  const {id} = useParams<{ id: string }>()
  const { state, dispatch } = useStateContext()
  const {
    dolAssociatedTags,
    dolAssociatedTopics,
    appConfig,
    user
  } = state
  const {
    global: {
      associatedTagLabel,
      ActivityInLabel
    },
  topicStrings: {
    noTopicsMessage,
  },
  tagStrings: {
    noTagsMessage,
  } } = appConfig;

  useEffect(() => {
    if (user.token) {
      FetchDolAssociatedTags(dispatch, profile.user_id, user.token)
      FetchDolAssociatedTopics(dispatch, profile.user_id, user.token)
    }
  }, [dispatch, profile.user_id, id, user.token])

  return (
    <div className="mini-profile">
      <InfluencerProfileCard
        id={profile.user_id}
        image={profileImageUrl(profile.profile_image_url_https)}
        screenName={profile.screen_name}
        name={profile.name}
        clickable={false}
        description={profile.description}
        location={profile.location}
        followers={profile.followers_count}
        friends={profile.friends_count}
        tweets={profile.statuses_count}
        isFavourited={profile.is_favorited}
        verified={profile.verified}
      />
      <AssociatedTags
        tags={dolAssociatedTags}
        title={associatedTagLabel}
        noTagsMessage={noTagsMessage}
      />
      <AssociatedTopics
        topics={dolAssociatedTopics}
        title={ActivityInLabel}
        noTopicsMessage={noTopicsMessage}
      />
      <HashtagsUsed
        userId={profile.user_id}
        title={hashtagsUsedTitle}
      />
    </div>
  );
}

export default MiniProfile;
