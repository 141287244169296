export enum ActionTypesEnum {
  FETCH_DOL = 'fetch_dol',
  NOTIFICATION = 'notification',
  USER_SEARCH = 'user_search',
  SET_USER = 'set_user',
  FETCH_DOL_USED_HASHTAGS = 'fetch_dol_used_hashtags',
  FETCH_DOL_USED_CLOUD_HASHTAGS = 'fetch_dol_used_cloud_hashtags',
  FETCH_DOL_ASSOCIATED_TAGS = 'fetch_dol_associated_tags',
  FETCH_DOL_ASSOCIATED_TOPICS = 'fetch_dol_associated_topics',
  FETCH_TOPICS = 'fetch_topics',
  FETCH_TOPIC = 'fetch_topic',
  FETCH_TOPIC_DOLS_BY_INFLUENCE = 'fetch_topic_dols_by_influence',
  FETCH_TOPIC_DOLS_BY_AUTHORITY = 'fetch_topic_dols_by_authority',
  FETCH_TOPIC_DOLS_BY_EXTERNAL_USER = 'fetch_topic_dols_by_external_user',
  FETCH_TAGS = 'fetch_tags',
  FETCH_TAG = 'fetch_tag',
  FETCH_TAG_SEARCH_STRINGS = 'fetch_tag_search_strings',
  FETCH_TOPICS_NETWORK_TOP_INFLUENCER = 'fetch_topics_top_influencer',
  FETCH_EGO_TOPICS_NETWORK = 'fetch_ego_topics_network',
  FETCH_SAVED_TWITTER_USER = 'fetch_saved_twitter_user',
  DELETE_SAVED_TWITTER_USER = 'delete_saved_twitter_user',
  POST_SAVED_TWITTER_USER = 'post_saved_twitter_user',
  RESET_SAVED_TWITTER_USER = 'reset_saved_twitter_user',
  FETCH_SAVED_TWITTER_USERS = 'fetch_saved_twitter_users',
  RESET_SAVED_TWITTER_USERS = 'reset_saved_twitter_users',
  SET_REDIRECT_URL = 'set_redirect_url'
}
