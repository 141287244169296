import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FlatButton } from '@visformatics/components'
import { FetchTopics, PutTopic } from '../../../dispatchers'
import { useStateContext } from '../../../Store/Store'
import TopicListItem from '../../../components/Topics/TopicListItem'
import './styles.scss'

const TopicList = () => {
  const history = useHistory();
  const { state, dispatch } = useStateContext();
  const {
    topics,
    appConfig: { topicStrings, global },
    user,
  } = state

  useEffect(() => {
    if (!topics) FetchTopics(dispatch, user.token);
  }, [dispatch, topics, user.token])

  const handleToggle = (topicObject) => PutTopic(
    dispatch, 
    topicObject, 
    user.token, 
    `/topics`
  );


  const handleCreateTopicAction = () => history.push('/topics/create')

  if (topics && topics.length > 0) {
    return (
      <div className="topics">
        <div className="topics_title">
          <h1>{topicStrings.title}</h1>
          <div className="action_container">
            <FlatButton
              click={handleCreateTopicAction}
              children={global.createTopic}
            />
          </div>
        </div>
        <ul>
          {topics.map((topic) => (
            <TopicListItem
              key={`${topic.topic_text}-${topic.topic_id}`}
              topic={topic}
              updateTopicAction={handleToggle}
              activeString={global.activeLabel}
              editString={global.editButton}
              viewString={global.viewButton}
            />
          ))}
        </ul>
      </div>
    )
  }

  return (
    <div className="topics topics-no-results">
      <h2>{topicStrings.noTopicsMessage}</h2>
    </div>
  )
}

export default TopicList
