import moment from 'moment'

/**
 * Group array by a column name
 * @param {Array} items
 * @param {String} key
 * @returns Array
 */
export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  ) || []

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 * @param format String e.g. DD-MM-YYYY HH:MM
 */
export const getDate = (epoch, format) => {
  const buildDate = moment(epoch).format(format)
  return buildDate
}
