import {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {
  FetchTopic, 
  PostSavedTwitterUser,
  DeleteSavedTwitterUser,
  FetchSavedTwitterUsers,
  FetchTopicDolsExternalUser,
} from '../../../dispatchers'
import {TopicDolType} from '../../../Types'
import {useStateContext} from '../../../Store/Store'
import {profileImageUrl} from '../../../Helpers'
import {useQueryStrings} from '../../../Hooks';
import MiniProfile from '../../../components/MiniProfile'
import InfluencerProfileCard from '../../../components/InfluencerProfileCard'
import Pagination from '../../../components/Pagination';
import './style.scss'
import Button from '../../../components/Button';
import Toggle from '../../../components/Toggle';

import {Skeleton} from '@material-ui/lab';

const NetworkInfluencers = () => {
  const history = useHistory();
  const query = useQueryStrings();
  const {id} = useParams<{ id: string }>()
  const {state, dispatch} = useStateContext()
  const [selectedProfile, setSelectedProfile] = useState<TopicDolType | undefined>(undefined)
  const {
    topic,
    appConfig: {global},
    user,
    savedTwitterUsers,
    topicDolsByExternalUser
  } = state;
  const dolId = query.get('dol_id');
  const pageNumber = parseInt(query.get('page'));
  const [toggle, setToggle] = useState(false);
  const [isSaveToggled, setIsSaveToggled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);
  const {records, total_records} = topicDolsByExternalUser || {};
  const pageSize = 100;
  const data = toggle ? savedTwitterUsers?.records?.filter((topic) => topic.is_favorited) : records;
  const totalRecords = toggle ? 1 : total_records;

  const setPage = (val) => {
    setCurrentPage(val);
    setPageOffset(val * pageSize - pageSize);
  }

  useEffect(()=> {
    if (user.token && !topic) FetchTopic(dispatch, id, user.token);
  }, [topic, dispatch, id, user.token])

  useEffect(()=> {
    if (pageNumber && pageNumber !== currentPage) setPage(pageNumber);
  }, [pageNumber, currentPage])

  useEffect(() => {
    if (toggle && user.token && !savedTwitterUsers) {
      FetchSavedTwitterUsers(dispatch, user.token, id)
      setSelectedProfile(null)
    }
  }, [user.token, savedTwitterUsers, toggle, dispatch, id, isSaveToggled])

  useEffect(() => {
    dispatch({ type: 'fetch_topic_dols_by_external_user', payload: null });
    FetchTopicDolsExternalUser(
      dispatch,
      id,
      'betweenness_centrality',
      user.token,
      100,
      pageOffset
    )
    setIsSaveToggled(false)
  }, [dispatch, id, user.token, pageOffset, isSaveToggled]);

  useEffect(() => {
    setCurrentPage(1)
    setPageOffset(0)
    setSelectedProfile(null)
    history.replace(`/topic/${id}/network`);
  }, [toggle, id, history])

  useEffect(() => {
    data?.find((item) => {
      if (item.user_id === dolId) setSelectedProfile(item)
      return null;
    });
  },[data])

  const appendSearchParams = (key, value) => {
    const {location: {search}} = window;
    const params = new URLSearchParams(search);
    value ? params.set(key, value) : params.delete(key);
    history.replace({pathname: `/topic/${id}/network`, search: params.toString()});
  }

  const onClick = (userId) => {
    if (selectedProfile?.user_id === userId) {
      appendSearchParams('dol_id', '')
      return setSelectedProfile(undefined);
    }

    data.find((item) => {
      if (item.user_id === userId) {
        appendSearchParams('dol_id', item.user_id);
        return setSelectedProfile(item);
      }
      return null;
    });
  }
  const isSelected = (id) => selectedProfile?.user_id === id

  const changePage = (val) => {
    if (toggle) {
      dispatch({type: 'fetch_saved_twitter_users', payload: null});
      setIsSaveToggled(false)
    }
    setPage(val);
  } 

  const onPaginationClick = (val) => {
    changePage(val);
    val === 1 ? appendSearchParams('page', '') : appendSearchParams('page', val);
    document.getElementById('profile-list').scrollTop = 0;
  }

  const handleTwitterUserSave = () => {
    if (selectedProfile.is_favorited) {
      DeleteSavedTwitterUser(dispatch, user.token, dolId);
      return setIsSaveToggled(true);
    }
    PostSavedTwitterUser(dispatch, id, user.token, dolId)
    return setIsSaveToggled(true)
  }

  return (
    <div className="network-influencers">
      <div className="network-influencers-container">
        <div className="profile-list-container">
          <div className="dol-network-page-header">
            <div>
              <h1>{topic?.topic_text}</h1>
              <h3>{toggle ? global.savedDolNetworkPageDescription : global.dolNetworkPageDescription}</h3>
            </div>
            <div className="toggle-card">
              <p>
                {toggle ? 'View all DOLs' : 'View saved DOLs'}
              </p>
              <Toggle
                name="toggle"
                onChange={() => setToggle((toggle) => !toggle)}
                isChecked={toggle}
              />
            </div>
          </div>
          <div className="profiles-list" id="profile-list">
            {toggle && data?.length === 0 && (
              <h3 className="no-record-message">
                You have no saved twitter users
              </h3>
            )}
            {(!data) &&
              <div className='skeleton-container'>
                {Array.from(Array(100).keys()).map((_, index) => {
                  return (
                    <Skeleton
                      key={index}
                      variant="rect"
                      height={182}
                      width={582}
                    />
                  )
                })}
              </div>
            }
            {data && data?.map((item: TopicDolType) => {
              return (
                <InfluencerProfileCard
                  key={item.user_id}
                  id={item.user_id}
                  image={profileImageUrl(item.profile_image_url_https)}
                  screenName={item.screen_name}
                  name={item.name}
                  description={item.description}
                  location={item.location}
                  onClick={onClick}
                  isSelected={isSelected}
                  followers={item.followers_count}
                  friends={item.friends_count}
                  tweets={item.statuses_count}
                  isFavourited={item.is_favorited}
                  verified={item.verified}
                />
              )
            })}
          </div>
          <div className="pagination-container">
            {data && <Pagination
              onClick={onPaginationClick}
              totalCount={totalRecords}
              currentPage={currentPage}
              pageSize={pageSize}
              disabled={!data}
            />}
          </div>
        </div>
        <div className="mini-profile-container">
          <div className="mini-profile-header-grid">
            <div className="mini-profile-headings">
              <h2>{global.profilePreviewSubheading}</h2>
              <h3>{global.profilePreviewDescription}</h3>
            </div>
            <div className="save-user-button-container">
              {selectedProfile && (
                <Button
                  onClick={handleTwitterUserSave}
                  variant={selectedProfile?.is_favorited ? 'outlined' : 'contained'}
                >
                  {selectedProfile?.is_favorited ? 'Unsave' : 'Save Profile'}
                </Button>
              )}
            </div>
          </div>
          {!selectedProfile && (
            <h3 className="mini-profile-default-text">
              {global.profilePreviewDefaultText}
            </h3>
          )}
          {selectedProfile && (
            <>
              <MiniProfile
                profile={selectedProfile}
                hashtagsUsedTitle={global.hashtagsUsedTitle}
              />
              <div className="profile-button-container">
                <a className="profile-button" href={`/profile/${selectedProfile?.user_id}`}>
                  {global.profileButtonLabel}
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default NetworkInfluencers
