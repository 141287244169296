import React, { ReactNode } from 'react'

import './Checkbox.css'

type Props = {
  checked?: boolean
  label: ReactNode
}

export const Checkbox = ({ checked, label }: Props) => {
  return (
    <div className="checkbox">
      <input
        type="checkbox"
        defaultChecked={checked ? checked : false}
        value="test"
      />
      {label}
    </div>
  )
}
