import React from 'react'

import { Text } from '../../typography/text/Text'

import './PublicationBanner.css'

type Props = {
  authors: string[]
  count?: number
  extract: string
  title: string
}

export const PublicationBanner = ({
  authors,
  count,
  extract,
  title,
}: Props) => {
  return (
    <div className="banner">
      {count && <div className="banner__counter">{count}.</div>}
      <div className="banner__content">
        <Text type="label">{title}</Text>
        <Text>{authors ? authors.join(', ') : ''}</Text>
        <Text type="caption">{extract}</Text>
      </div>
    </div>
  )
}
