import './style.scss'
import VerifiedIcon from '../VerifiedIcon'
import LocationIcon from '@material-ui/icons/LocationOn'
import FavouriteIcon from '@material-ui/icons/Favorite'
import TwitterStats from '../TwitterStats'
import InfluencerDetailsProps from './Type';

const InfluencerProfileCard = ({
  id,
  image,
  screenName,
  name,
  description,
  location,
  onClick,
  clickable = true,
  isSelected,
  followers,
  friends,
  tweets,
  isFavourited,
  verified
}: InfluencerDetailsProps) => {

  return (
    <div
      className={
        `influencer-profile-card
        ${isSelected && isSelected(id) ? 'selected' : ''}
        ${clickable ? 'clickable' : ''}
        `
      }
      onClick={onClick ? () => onClick(id) : null}
    >
      <div className='influencer-image-container'>
        <img 
          src={image} 
          className='influencer-image'
          alt={`Avatar of ${name}`} 
        />
        {isFavourited && <FavouriteIcon className='favourite-icon' />}
      </div>
      <div className='influencer-details'>
        <h2 className='influencer-name'>{name} @{screenName} {verified && <VerifiedIcon />}</h2>
        {location &&
          <p className='influencer-location'>
            <LocationIcon
              className='location-pin'
            />
            {location}
          </p>
        }
        <p className='influencer-bio'>{description}</p>

        <TwitterStats
          followerCount={followers}
          tweetCount={tweets}
          friendsCount={friends}
        />
      </div>
      {isSelected && <div className="selected-indicator"/>}
    </div>
  )
}

export default InfluencerProfileCard
