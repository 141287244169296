import {ReactComponent as CloseIcon} from '../../assets/close-menu-icon.svg'
import {ModalProps} from './interface'
import './style.scss'

const Modal = ({
  title,
  titleIcon,
  textContent,
  onConfirm,
  modalActionText,
  modalCloseText,
  onCancel,
  closeable,
}: ModalProps) => {

  return (
    <div className="modal">
      <div className="overlay">
        <div className="modal_container">
          <div className="close-button">
            {closeable && (
              <button onClick={onCancel}>
                <CloseIcon />
              </button>
            )}
          </div>
          <div className="modal_container__header">
            {title && (
              <div className="modal_container__title">
                <h3>{title}</h3>
                {titleIcon && 
                  <img src={titleIcon} alt='icon' />
                }
              </div>
            )}
          </div>
          <div className="modal_container__main">
            <div className="modal_container__main-content">
              <p>{textContent}</p>
            </div>
          </div>
          <div className="modal-buttons">
            {onConfirm &&
              <button onClick={onConfirm}>
                {modalActionText}
              </button>
            }
            {onCancel && 
              <button onClick={onCancel}>
                {modalCloseText}
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
