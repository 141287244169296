import {gql} from '@apollo/client'

/**
 * Get a list of users
 */
export const GET_USERS = gql`
  query GetUsers {
    insights_service_users(order_by: { id: asc }) {
      id
      name
      email
      users_roles {
        role {
          users_roles {
            role {
              id
              title
            }
          }
        }
      }
    }
  }
`

/**
 * Get a list of permissions
 */
export const GET_PERMISSIONS = gql`
  query GetPermissions($filters: [insights_service_permissions_bool_exp]) {
    insights_service_permissions(
      where: { _and: $filters }
      order_by: { id: asc }
    ) {
      is_active
      title
      id
    }
  }
`

/**
 * Mutation that adds a permission
 */
export const ADD_PERMISSION = gql`
  mutation InsertPermission($title: String) {
    insert_insights_service_permissions(
      objects: { title: $title, is_active: false }
    ) {
      affected_rows
      returning {
        id
        title
      }
    }
  }
`

/**
 * Mutation that deletes a permission
 */
export const DELETE_PERMISSION = gql`
  mutation DeletePermission($id: Int!) {
    delete_insights_service_permissions_by_pk(id: $id) {
      id
      title
    }
  }
`

/**
 * Get a list of roles
 */
export const GET_ROLES = gql`
  query GetRoles {
    insights_service_roles(order_by: { id: asc }) {
      id
      title
      role_permissions {
        permission {
          id
          title
        }
      }
    }
  }
`

/**
 * Mutation that adds a role
 */
export const ADD_ROLE = gql`
  mutation InsertRole($title: String) {
    insert_insights_service_roles(objects: { title: $title }) {
      affected_rows
      returning {
        title
      }
    }
  }
`

/**
 * Mutation that deletes a role
 */
export const DELETE_ROLE = gql`
  mutation DeleteRole($id: Int!) {
    delete_insights_service_roles_by_pk(id: $id) {
      title
      id
    }
  }
`

/**
 * Mutation to update permission
 */
export const UPDATE_PERMISSION = gql`
  mutation UpdatePermission($id: Int, $title: String, $is_active: Boolean) {
    update_insights_service_permissions(
      _set: { title: $title, is_active: $is_active }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
      returning {
        is_active
        title
        id
      }
    }
  }
`

/**
 * Mutation to update role
 */
export const UPDATE_ROLE = gql`
  mutation UpdateRole($id: Int, $title: String) {
    update_insights_service_roles(
      where: { id: { _eq: $id } }
      _set: { title: $title }
    ) {
      affected_rows
      returning {
        title
        id
      }
    }
  }
`

/**
 * Mutation that deletes permissions from roles
 */
export const DELETE_ROLE_PERMISSIONS = gql`
  mutation DeleteRolePermissions(
    $filters: [insights_service_role_permissions_bool_exp]
  ) {
    delete_insights_service_role_permissions(where: { _and: $filters }) {
      affected_rows
      returning {
        role_id
        permission_id
        id
      }
    }
  }
`

/**
 * Mutation that adds permissions to roles
 */
export const ADD_ROLE_PERMISSIONS = gql`
  mutation AddRolePermissions(
    $objects: [insights_service_role_permissions_insert_input!]!
  ) {
    insert_insights_service_role_permissions(objects: $objects) {
      affected_rows
      returning {
        id
        permission_id
        role_id
      }
    }
  }
`

/**
 * Mutation that updates user
 */
export const UPDATE_USER = gql`
  mutation UpdateUser($id: Int, $name: String, $email: String) {
    update_insights_service_users(
      where: { id: { _eq: $id } }
      _set: { name: $name, email: $email }
    ) {
      affected_rows
      returning {
        id
        name
        email
      }
    }
  }
`

/**
 * Mutation that deletes user roles
 */
export const DELETE_USER_ROLES = gql`
  mutation DeleteUserRoles($filters: [insights_service_users_roles_bool_exp]) {
    delete_insights_service_users_roles(where: { _and: $filters }) {
      affected_rows
      returning {
        id
        role_id
        user_id
      }
    }
  }
`

/**
 * Mutation that adds roles to users
 */
export const ADD_USER_ROLES = gql`
  mutation AddUserRoles(
    $objects: [insights_service_users_roles_insert_input!]!
  ) {
    insert_insights_service_users_roles(objects: $objects) {
      affected_rows
      returning {
        id
        user_id
        role_id
      }
    }
  }
`

/**
 * Get questions by therapy area
 */
export const GET_QUESTIONS = gql`
  query GetQuestions($therapy_area: String, $is_active: Boolean) {
    insights_service_questions(
      where: {
        therapy_area: { _eq: $therapy_area }
        is_active: { _eq: true }
      }
      order_by: { position: asc }
    ) {
      id
      heading_id
      is_active
      parent_id
      position
      therapy_area
      question_type_id
      title
      updated_at
      created_at
      allow_other
      taggable
      question_heading {
        id
        title
      }
      question_type {
        id
        title
      }
      answers(where: { is_predefined: { _eq: true } }) {
        id
        title
        position
        is_predefined
        question_id
      }
    }
  }
`

/**
 * Get questions types
 */
export const GET_QUESTION_TYPES = gql`
  query getQuestionTypes {
    insights_service_question_type {
      id
      title
    }
  }
`

/**
 * Mutation that inserts questions
 */
export const ADD_QUESTIONS = gql`
  mutation AddQuestions($objects: [insights_service_questions_insert_input!]!) {
    insert_insights_service_questions(
      objects: $objects
      on_conflict: { constraint: questions_pkey, update_columns: position }
    ) {
      affected_rows
      returning {
        id
        title
        therapy_area
        allow_other
        position
        heading_id
        question_type_id
        is_active
        updated_at
        created_at
        answers {
          id
          title
          position
          is_predefined
          question_id
        }
      }
    }
  }
`

/**
 * Mutation that deletes questions
 */
export const DELETE_QUESTION = gql`
  mutation DeleteQuestion($id: Int!) {
    delete_insights_service_questions_by_pk(id: $id) {
      id
      title
    }
  }
`

/**
 * Mutation that toggles the is_active field in questions
 */
export const TOGGLE_ACTIVE_QUESTION = gql`
  mutation ToggleActiveQuestion($id: Int!, $is_active: Boolean) {
    update_insights_service_questions_by_pk(
      pk_columns: { id: $id }
      _set: { is_active: $is_active }
    ) {
      id
      is_active
    }
  }
`

/**
 * Mutation that toggles the is_active field in questions
 */
export const TOGGLE_ACTIVE_TAGGABLE = gql`
  mutation ToggleTaggableQuestion($id: Int!, $taggable: Boolean) {
    update_insights_service_questions_by_pk(
      pk_columns: { id: $id }
      _set: { taggable: $taggable }
    ) {
      id
      taggable
    }
  }
`

/**
 * Mutation that updates a question heading title
 */
export const UPDATE_QUESTION_HEADING = gql`
  mutation UpdateQuestionHeading($id: Int!, $title: String) {
    update_insights_service_question_headings_by_pk(
      pk_columns: { id: $id }
      _set: { title: $title }
    ) {
      id
      title
    }
  }
`

/**
 * Mutation that adds a question heading
 */
export const ADD_QUESTION_HEADING = gql`
  mutation AddQuestionHeading($title: String) {
    insert_insights_service_question_headings_one(object: { title: $title }) {
      id
      title
    }
  }
`

/**
 * Delete question heading
 */
export const DELETE_QUESTION_HEADING = gql`
  mutation DeleteQuestionHeading($id: Int!) {
    delete_insights_service_question_headings_by_pk(id: $id) {
      id
      title
    }
  }
`

/**
 * Mutation that inserts capture insights therapy areas
 */
export const ADD_CAPTURE_INSIGHTS_TA = gql`
  mutation AddCaptureInsightsTherapyAreas(
    $objects: [insights_service_therapy_areas_insert_input!]!
  ) {
    insert_insights_service_therapy_areas(
      objects: $objects
      on_conflict: { constraint: therapy_areas_pkey, update_columns: position }
    ) {
      affected_rows
      returning {
        id
        identifier
        display_name
        position
        is_active
      }
    }
  }
`

/**
 * Mutation that updates therapy_areas
 */
export const UPDATE_CAPTURE_INSIGHTS_TA = gql`
  mutation UpdateCaptureInsightsTherapyArea(
    $id: Int!
    $identifier: String
    $display_name: String
    $position: Int
    $is_active: Boolean
  ) {
    update_insights_service_therapy_areas_by_pk(
      pk_columns: { id: $id }
      _set: {
        identifier: $identifier
        display_name: $display_name
        position: $position
        is_active: $is_active
      }
    ) {
      id
      identifier
      display_name
      position
      is_active
    }
  }
`

/**
 * Mutation that deletes capture insight therapy area
 */
export const DELETE_CAPTURE_INSIGHTS_TA = gql`
  mutation DeleteCaptureInsightsTherapyArea($id: Int!) {
    delete_insights_service_therapy_areas_by_pk(id: $id) {
      id
      display_name
    }
  }
`

/**
 * Mutation that toggles the is_active field in therapy_areas
 */
export const TOGGLE_ACTIVE_CAPTURE_INSIGHTS_TA = gql`
  mutation ToggleActiveCaptureInsightsTherapyArea(
    $id: Int!
    $is_active: Boolean
  ) {
    update_insights_service_therapy_areas_by_pk(
      pk_columns: { id: $id }
      _set: { is_active: $is_active }
    ) {
      id
      is_active
    }
  }
`

/**
 * Get a list of tags
 */
export const GET_TAGS = gql`
  query GetTags($is_active: Boolean, $ta_id: Int) {
    insights_service_tags(
      where: { is_active: { _eq: $is_active }, ta_id: { _eq: $ta_id } }
      order_by: { id: desc }
    ) {
      id
      is_active
      ta_id
      title
      colour
      therapy_area {
        identifier
        display_name
      }
    }
  }
`

/**
 * Mutation that updates the is_active column for a specified tag id
 */
export const TOGGLE_ACTIVE_TAG = gql`
  mutation ToggleActiveTags($id: Int!, $is_active: Boolean, $blob: jsonb) {
    update_insights_service_tags_by_pk(
      pk_columns: { id: $id }
      _set: { is_active: $is_active }
    ) {
      id
      is_active
    }
    update_insights_service_tags_blob(
      where: { tag_id: { _eq: $id } }
      _set: { blob: $blob }
    ) {
      affected_rows
      returning {
        id
        blob
        tag_id
      }
    }
  }
`

/**
 * Mutation that updates tag for a specified id
 */
export const UPDATE_TAG = gql`
  mutation ToggleActiveTags(
    $id: Int!
    $title: String
    $ta_id: Int
    $colour: String
    $is_active: Boolean
    $blob: jsonb
  ) {
    update_insights_service_tags_by_pk(
      pk_columns: { id: $id }
      _set: {
        title: $title
        ta_id: $ta_id
        colour: $colour
        is_active: $is_active
      }
    ) {
      id
      title
      ta_id
      colour
      is_active
    }
    update_insights_service_tags_blob(
      where: { tag_id: { _eq: $id } }
      _set: { blob: $blob }
    ) {
      affected_rows
      returning {
        id
        blob
        tag_id
      }
    }
  }
`

/**
 * Mutation that removes tag by id
 */
export const DELETE_TAG = gql`
  mutation DeleteTag($id: Int!) {
    delete_insights_service_tags_by_pk(id: $id) {
      id
      title
    }
  }
`

/**
 * Mutation to add tag
 */
export const ADD_TAGS = gql`
  mutation addTag($objects: [insights_service_tags_insert_input!]!) {
    insert_insights_service_tags(objects: $objects) {
      affected_rows
      returning {
        id
        ta_id
        title
        is_active
        colour
        tags_blobs {
          id
          tag_id
          blob
        }
      }
    }
  }
`
