import React from 'react'
import PropTypes from 'prop-types'

export function SimpleSelect({
  addEmpty,
  label,
  options,
  value,
  onChangeHandle,
}) {
  return (
    <label className="select">
      {label && <span className="text-gray-700">{label}</span>}
      <select
        className="select select-slim"
        {...(value ? { value } : {})}
        {...(onChangeHandle
          ? { onChange: (e) => onChangeHandle(e.target.value) }
          : {})}
      >
        {addEmpty && <option value=""></option>}
        {options?.map((option, index) => (
          <option key={index} value={option?.key}>
            {option?.label}
          </option>
        ))}
      </select>
    </label>
  )
}

SimpleSelect.propTypes = {
  addEmpty: PropTypes.bool,
  options: PropTypes.array,
  label: PropTypes.string,
  value: PropTypes.string,
  onChangeHandle: PropTypes.func,
}

export default SimpleSelect
