import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

export const AddEditHeadingForm = ({ values, onUpdate, onCancel }) => {
  const [title, setTitle] = useState('')

  useEffect(() => {
    setTitle(values?.title || '')
  }, [values])

  return (
    <>
      <div className="grid grid-cols-1 gap-6">
        <label className="block">
          <span className="text-gray-700">Question Heading</span>
          <input
            type="text"
            className="block w-full input"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </label>
      </div>
      <div className="modal-action__container">
        <button
          type="button"
          className="button-update"
          onClick={() => onUpdate({ title })}
        >
          Update
        </button>
        <button type="button" className="button-cancel" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </>
  )
}

AddEditHeadingForm.propTypes = {
  values: PropTypes.object,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
}
