type ReturnedValue = string | { [key: string]: unknown } | []

const hasEmptyValues = (object) => {
  return Object.values(object).some(
    (value: ReturnedValue) =>
      value === null || value === '' || value.length === 0
  )
}

const handleChange = (event: React.ChangeEvent<HTMLInputElement>, setter) => {
  const { name, value } = event.target
  return setter((prevState) => ({ ...prevState, [name]: value }))
}

const setInitialValues = (values, setter) => {
  setter((prevState) => ({
    ...prevState,
    ...values,
  }))
}

const separateHashtags = (tagString) => {
  return tagString.split(',').map((tag) => tag.trim())
}

export {
  hasEmptyValues,
  handleChange,
  setInitialValues,
  separateHashtags
}
