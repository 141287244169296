import React from 'react';
import {PaginationLinkType} from './types';

const PaginationLink = ({onClick, label, isDisabled, cssClass}: PaginationLinkType) => {
  return(
    <li>
      <button className={cssClass} onClick={onClick} disabled={isDisabled}>
        {label}
      </button>
    </li>
  )
}

export default PaginationLink;
