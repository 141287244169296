import axios from 'axios'
import AppConfig from '../config/AppConfig';
import { setApiHeader } from '../Helpers';

export const FetchTagSearchStrings = async(dispatch, id, token) => {
  const { global } = AppConfig;
  
  return axios
    .get(`${global.apiUrl}/tag/${id}/search_strings`, { headers: setApiHeader(token) })
    .then((res) => {
      dispatch({ type: 'fetch_tag_search_strings', payload: res.data })
    })
    .catch((err) => {
      dispatch({
        type: 'notification',
        payload: { message: err, type: 'error' },
      })
      console.error('There was a problem fetching Tag search strings: ', err)
    })
}
