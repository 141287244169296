import React from 'react'
import ToggleProps from './interface'
import './style.scss'

/**
 * iOS-themed Toggle component
 */
const Toggle = ({ name, onChange, isChecked, isDisabled }: ToggleProps) => {
  return (
    <input
      id={name}
      name={name}
      type="checkbox"
      onChange={onChange}
      className="switch"
      checked={isChecked}
      disabled={isDisabled}
    />
  )
}

export default Toggle
