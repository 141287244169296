import { Auth0Provider } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom';

const { NX_AUTH_DOMAIN, NX_AUTH_CLIENT_ID, NX_AUTH_AUDIENCE } = process.env

const AuthProvider = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={NX_AUTH_DOMAIN}
      clientId={NX_AUTH_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={NX_AUTH_AUDIENCE}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  )
}

export default AuthProvider
