import axios from 'axios'
import AppConfig from '../config/AppConfig';
import { setApiHeader } from '../Helpers';

export const FetchTag = async(dispatch, id, token) => {
  const { global } = AppConfig;

  return axios
    .get(`${global.apiUrl}/tag/${id}`, { headers: setApiHeader(token) })
    .then((res) => {
      dispatch({ type: 'fetch_tag', payload: res.data })
    })
    .catch((err) => {
      dispatch({
        type: 'notification',
        payload: { message: err, type: 'error' },
      })
      console.error('There was a problem fetching Tag: ', err)
    })
}
