/**
 * List of values that are used to create helper classes for (in pixels)
 * e.g. m0 - margin: 0px
 */
const values = ['100', '200', '300', '400', '500', '600', '700', '800']

/**
 * Loop through list of values and generate classes
 * @return Object - object of generated classes
 */
const prepareClasses = () => {
  let output = {}

  const classes = values.map((v) => {
    const value = `${v}px`
    return {
      [`fw${v}`]: { fontWeight: value },
    }
  })

  Object.keys(classes).map((key) => {
    let obj = classes[key]
    output = {
      ...output,
      ...obj,
    }
    return {}
  })

  return output
}

/**
 * Export object of margins
 */
export const fonts = {
  ...prepareClasses(),
}
