const create = ({ appId = '' }) => {
  const init = () => {
    (function () {
      var w = window
      var ic = w.Intercom
      if (typeof ic === 'function') {
        ic('reattach_activator')
        ic('update', w.intercomSettings)
      } else {
        var d = document
        var i = function () {
          i.c(arguments)
        }
        i.q = []
        i.c = function (args) {
          i.q.push(args)
        }
        w.Intercom = i
        var l = function () {
          var s = d.createElement('script')
          s.type = 'text/javascript'
          s.async = true
          s.src = 'https://widget.intercom.io/widget/' + appId
          var x = d.getElementsByTagName('script')[0]
          x.parentNode.insertBefore(s, x)
        }
        if (document.readyState === 'complete') {
          l()
        } else if (w.attachEvent) {
          w.attachEvent('onload', l)
        } else {
          w.addEventListener('load', l, false)
        }
      }
    })()
  }

  const configure = (options = {}) => {
    window &&
      window.Intercom &&
      window.Intercom('boot', { app_id: appId, ...options })
  }

  const update = (details) => {
    if (details) {
      window && window.Intercom && window.Intercom('update', details)
    } else {
      window && window.Intercom && window.Intercom('update')
    }
  }

  const shutdown = () => {
    window && window.Intercom && window.Intercom('shutdown')
    localStorage.removeItem('intercom.intercom-state')
  }

  const handleClose = (setOpen) => {
    window.Intercom('onHide', () => {
      setOpen(false)
    })
  }

  const handleOpen = (setOpen) => {
    window.Intercom('onShow', () => {
      setOpen(true)
    })
  }

  return {
    init,
    configure,
    update,
    shutdown,
    handleClose,
    handleOpen,
  }
}

export default {
  create,
}
