import React, { ReactNode } from 'react'

import './FlatButton.css'

type Props = {
  children: ReactNode
  click?: (event?: React.MouseEvent<HTMLElement>) => void
  display?: 'primary' | 'secondary'
  fill?: boolean
  round?: boolean
  size?: 'small' | 'medium' | 'large'
	type?: 'button' | 'submit' | 'reset'
	disabled?: boolean
}

export const FlatButton = ({
  children,
  click,
  display,
  fill,
  round,
  size,
	type,
	disabled,
}: Props) => {
  const setFill = () => {
    return fill ? 'button__fill' : ''
  }

  const setRounded = () => {
    return round ? 'button__round' : 'button_square'
  }

  const setColor = () => {
    return display ? `button__${display}` : 'button__primary'
  }

  const setSize = () => {
    switch (size) {
      case 'large':
        return 'button__large'
      case 'small':
        return 'button__small'
      default:
        return 'button__medium'
    }
  }

  return (
    <button
      className={`button ${setRounded()} ${setColor()} ${setSize()} ${setFill()}`}
      type={type ? type : 'button'}
		onClick={click}
		disabled={disabled}
    >
      {children}
    </button>
  )
}
