import { useState } from 'react'

import { Input } from '@visformatics/components'
import { FormInputInterface } from './interface'

const FormInput = ({
  displayError,
  error,
  feedback,
  label,
  required,
  type,
  value,
}: FormInputInterface) => {
  const [inputValue, setInputValue] = useState(value)
  const [showError, setShowError] = useState<string>()

  const submitHandler = () => {
    if (required && (!inputValue || inputValue === '')) {
      setShowError(error)
      feedback(false)
      return
    }
    setShowError(null)
    feedback(true, inputValue)
  }

  const valueHandler = (event) => {
    setInputValue(event.target.value)
  }

  return (
    <Input
      blur={submitHandler}
      change={valueHandler}
      error={displayError ? error : showError}
      focus={submitHandler}
      labelText={label}
      type={type ?? 'text'}
      value={inputValue}
    />
  )
}

export { FormInput }
