import axios from 'axios'
import AppConfig from '../config/AppConfig';
import {setApiHeader} from '../Helpers';
import jwt_decode from 'jwt-decode';

export const PostSavedTwitterUser = async (dispatch, tag, token, dolId) => {
  const {global} = AppConfig;
  const {sub} = jwt_decode<{ sub: string }>(token);
  return axios
    .post(`${global.apiUrl}/external_user/${sub}/favorite/twitter_user`, {
      'user_id': dolId
    }, {headers: setApiHeader(token)})
    .then((res) => {
      dispatch({type: 'reset_saved_twitter_users'})
      dispatch({type: 'post_saved_twitter_user', payload: res.data})
    })
    .catch((err) => {
      dispatch({
        type: 'notification',
        payload: {message: err, type: 'error'},
      })
      console.error('An error occurred while saving a twitter user: ', err)
    })
}
