import { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'
import { RefreshIcon, PlusCircleIcon } from '@heroicons/react/outline'
import Loader from '../loader/loader'
import './styles.scss'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
})

export const QuestionsDraggable = ({
  isActiveFilter,
  setIsActiveFilter,
  questions,
  refetch,
  isLoading,
  addNew,
  update,
  onDelete,
  onReOrder,
  onArchive,
  onTaggable,
}) => {
  const [items, setItems] = useState([])

  useEffect(() => {
    setItems(questions)
  }, [questions])

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    const newOrder = reorder(
      items,
      result.source.index,
      result.destination.index
    )
    const normalizedOrder = newOrder.map(
      (
        {
          id,
          allow_other,
          title,
          question_type_id,
          heading_id,
          therapy_area,
          is_active,
          taggable,
          updated_at,
          created_at,
        },
        index
      ) => {
        return {
          id,
          allow_other,
          title,
          question_type_id,
          heading_id,
          therapy_area,
          is_active,
          taggable,
          updated_at,
          created_at,
          position: index,
        }
      }
    )
    onReOrder(normalizedOrder)
    setItems(newOrder)
  }

  return (
    <div className="container mt-8">
      <div className="title">Questions</div>
      <div className="mt-4">
        <div className="items-center sm:justify-between sm:flex">
          <div className="grid items-center grid-flow-col gap-4">
            {refetch && (
              <div className="mb-4 sm:mb-0">
                <button
                  className="button button-icon"
                  onClick={() => refetch()}
                >
                  <RefreshIcon aria-hidden="true" /> Refresh
                </button>
              </div>
            )}
            {addNew && (
              <div className="mb-4 sm:mb-0">
                <button className="button button-icon" onClick={addNew}>
                  <PlusCircleIcon aria-hidden="true" /> Add new
                </button>
              </div>
            )}

            <div className="mb-4 sm:mb-0">
              <label className="block mt-2 sm:mt-0">
                <select
                  className="select select-slim"
                  value={isActiveFilter}
                  onChange={(e) => setIsActiveFilter(e.target.value)}
                >
                  <option value="">All</option>
                  <option value={true}>Active</option>
                  <option value={false}>Archived</option>
                </select>
              </label>
            </div>
          </div>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="relative mt-4 draggable-list__container"
            >
              {isLoading && <Loader overlay={true} />}

              {items.map(
                (
                  {
                    id,
                    title,
                    is_active,
                    question_heading,
                    question_type,
                    taggable,
                  },
                  index
                ) => (
                  <Draggable
                    key={id.toString()}
                    draggableId={id.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        className={`draggable-list${
                          snapshot.isDragging ? ' dragging' : ''
                        }${is_active ? ' active' : ''}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div className="items-center justify-between gap-4 md:flex">
                          <div>
                            <div className="items-center justify-between gap-4 md:flex">
                              <span
                                onClick={() => update(items[index])}
                                className="block sm:inline-block question-id"
                              >
                                QUESTION-{id}
                              </span>
                              <span
                                onClick={() => update(items[index])}
                                className="no-underline ease-in-out cursor-pointer lg:max-w-xl lg:truncate hover:underline"
                              >
                                <abbr title={title}>{title}</abbr>
                              </span>
                            </div>

                            <div className="relative z-0 items-center gap-4 lg:gap-2 sm:flex-wrap sm:justify-start sm:flex">
                              {question_type?.title && (
                                <span className={`chip mr-2`}>
                                  {question_type?.title}
                                </span>
                              )}
                              {question_heading?.title && (
                                <span className={`chip mr-2`}>
                                  {question_heading?.title}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="flex self-end gap-4">
                            {question_type?.title === 'textarea' ? (
                              <button
                                onClick={() =>
                                  onTaggable({ id, taggable: !taggable })
                                }
                                className="relative text-indigo-500 ease-in-out hover:text-indigo-900 hover:underline"
                              >
                                {taggable
                                  ? 'Disallow Tagging'
                                  : 'Allow Tagging'}
                              </button>
                            ) : null}

                            <button
                              onClick={() =>
                                onArchive({ id, is_active: !is_active })
                              }
                              className="relative text-orange-500 ease-in-out hover:text-orange-900 hover:underline"
                            >
                              {is_active ? 'Archive' : 'Restore'}
                            </button>
                            <button
                              onClick={() => onDelete({ id, title })}
                              className="relative text-red-500 ease-in-out hover:text-red-900 hover:underline"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                )
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

QuestionsDraggable.propTypes = {
  isActiveFilter: PropTypes.string,
  setIsActiveFilter: PropTypes.func,
  questions: PropTypes.array,
  refetch: PropTypes.func,
  isLoading: PropTypes.bool,
  addNew: PropTypes.func,
  update: PropTypes.func,
  onDelete: PropTypes.func,
  onReOrder: PropTypes.func,
  onArchive: PropTypes.func,
  onTaggable: PropTypes.func,
}

export default QuestionsDraggable
