import { useReducer, createContext, useContext } from 'react'
import { reducer } from './reducer'

const initialState = (window.Cypress && window.initialState) || {
  notification: null,
  user: null,
}

const StoreContext = createContext(null)
const StoreDispatchContext = createContext(null)

export function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <StoreContext.Provider value={state}>
      <StoreDispatchContext.Provider value={dispatch}>
        {children}
      </StoreDispatchContext.Provider>
    </StoreContext.Provider>
  )
}

export function useStoreState() {
  const context = useContext(StoreContext)
  if (context === undefined) {
    throw new Error('useStoreState must be used within a StoreProvider')
  }
  return context
}

export function useStoreDispatch() {
  const context = useContext(StoreDispatchContext)
  if (context === undefined) {
    throw new Error('useStoreDispatch must be used within a StoreProvider')
  }
  return context
}
