import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'

const MuiIconButton = ({ icon, ...rest }) => {
  return <IconButton children={icon} {...rest} />
}

MuiIconButton.propTypes = {
  icon: PropTypes.element,
}

export default MuiIconButton
