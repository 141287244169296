import React, { useContext } from 'react'
import {
  ApolloProvider as Provider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useAuth0 } from '@auth0/auth0-react'
import fetch from 'cross-fetch'
import { EnvironmentContext } from './environment-provider/environment-provider'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environments } from '@visformatics/components'

export const ApolloProvider = ({ children }) => {
  const { getAccessTokenSilently, isLoading } = useAuth0()
  const { state } = useContext(EnvironmentContext)

  const httpLink = new HttpLink({
    uri: state?.environment?.graphql_uri || environments[0].graphql_uri,
    fetch,
  })

  const withTokenLink = setContext(async () => {
    if (isLoading) return { token: '' }

    // else check if valid token exists with client already and set if so
    const token = await getAccessTokenSilently()
    return { token }
  })

  const authLink = setContext((_, { headers, token }) => {
    return {
      headers: {
        ...headers,
        ...(token ? { authorization: `Bearer ${token}` } : {}),
      },
    }
  })

  const client = new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false,
      typePolicies: {
        Query: {
          fields: {},
        },
      },
    }),
    link: ApolloLink.from([withTokenLink, authLink, httpLink]),
    connectToDevTools: process.env.NODE_ENV === 'development',
  })

  return <Provider client={client}>{children}</Provider>
}

export default ApolloProvider
