import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

export const AddEditCiTasForm = ({ isEdit, values, onUpdate, onCancel }) => {
  const [id, setId] = useState(null)
  const [identifier, setIdentifier] = useState('')
  const [display_name, setDisplayName] = useState('')
  const [position, setPosition] = useState(0)
  const [isActive, setIsActive] = useState(false)

  // Initialise default values for edit
  useEffect(() => {
    setId(values?.id || null)
    setIdentifier(values?.identifier || '')
    setDisplayName(values?.display_name || '')
    setPosition(values?.position || 0)
    setIsActive(values?.is_active || false)
  }, [values])

  // On add / edit question
  const handleOnUpdate = () => {
    onUpdate({
      ...(id ? { id } : {}),
      identifier,
      display_name,
      position: Number(position),
      is_active: isActive,
    })
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-3">
        {id && (
          <div className="mt-2 text-sm font-bold text-primary-600">TA-{id}</div>
        )}

        <label className="block">
          <span className="text-gray-700">Identifier</span>
          <input
            type="text"
            className="block w-full input"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
          />
        </label>

        <label className="block">
          <span className="text-gray-700">Display Name</span>
          <input
            type="text"
            className="block w-full input"
            value={display_name}
            onChange={(e) => setDisplayName(e.target.value)}
          />
        </label>

        <label className="block">
          <span className="text-gray-700">Position</span>
          <input
            type="number"
            className="block w-full input"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          />
        </label>

        <label className="checkbox-label">
          <input
            type="checkbox"
            className="checkbox-input"
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
          />
          <span className="ml-2">Active</span>
        </label>
      </div>

      <div className="modal-action__container">
        <button
          type="button"
          className="button-update"
          onClick={handleOnUpdate}
        >
          {isEdit ? 'Edit' : 'Add'} TA
        </button>

        <button type="button" className="button-cancel" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </>
  )
}

AddEditCiTasForm.defaultProps = {
  isEdit: false,
}

AddEditCiTasForm.propTypes = {
  isEdit: PropTypes.bool,
  values: PropTypes.object,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
}
