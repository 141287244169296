import './style.scss'
import {ButtonProps} from './interface';

const Button = ({children, onClick, variant, disabled}:ButtonProps) => {

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`btn btn_${variant}`}
    >
      {children}
    </button>
  )
}

export default Button
