import React from 'react'

const TopicDetails = ({  labels, details: { 
    search_string, 
    hashtags,
    from_date,
    to_date,
  } 
}) => {
  return (
    <div className="topic-card topic-details">
      <p>
        <label>{labels.searchString.label}:</label>
        {search_string}
      </p>
      <p className="hashtags">
        <label>{labels.hashtags.label}:</label>
        {hashtags.map((tag) => (
          <span key={tag}>{`#${tag}`}</span>
        ))}
      </p>
      <p>
        <label>{labels.fromDate.label}:</label>
        {from_date}
      </p>
      <p>
        <label>{labels.toDate.label}:</label>
        {to_date}
      </p>
    </div>
  )
}

export default TopicDetails 
