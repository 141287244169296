import React, { useContext, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import MuiIconButton from '../../../dmt/src/components/Buttons/MuiIconButton/MuiIconButton'
import { UserSupportContext } from './index'
import { ReactComponent as Intercom } from '../userSupport/intercom.svg'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ChevronLeft } from '@material-ui/icons'
import { useLocation } from 'react-router'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { globalStyles } from '../../../dmt/src/theme/globals'
import clsx from 'clsx'

const IntercomCustomBtn = () => {
  const props = useContext(UserSupportContext)
  const [open, setOpen] = useState(false)
  const isAuthorPage = useLocation()?.pathname?.includes('author')
  const classes = useStyles({ open, isAuthorPage })
  const { relative } = globalStyles()

  const handleOnClick = () => open ? props.handleClose(setOpen) : props.handleOpen(setOpen)

  return (
    <MuiIconButton
      id="support_launcher"
      onClick={handleOnClick}
      className={classes.iconButton}
    >
      <Avatar className={classes.avatar}>
        <div className={clsx(classes.iconWrapper, relative)}>
          <ChevronLeft className={classes.chevronLeft} />
          <Intercom className={classes.intercom} />
        </div>
      </Avatar>
    </MuiIconButton>
  )
}

const useStyles = makeStyles((theme) => ({
  iconButton: {
    position: 'absolute',
    right: 34,
    bottom: ({ isAuthorPage }) => (isAuthorPage ? '82px' : '34px'),
    zIndex: 3,
    [theme.breakpoints.up('md')]: {
      bottom: '34px !important',
      right: '34px',
    },
  },
  intercom: {
    opacity: ({ open }) => (open ? 0 : 1),
    position: 'absolute',
    transform: ({ open }) => open && 'rotate(30deg)',
    transition: 'transform 0.16s linear 0s, opacity 0.08s linear 0s',
  },
  chevronLeft: {
    top: 12,
    fontSize: '30px',
    opacity: ({ open }) => (open ? 1 : 0),
    position: 'absolute',
    transform: ({ open }) => open && 'rotate(-90deg)',
    transition: 'transform 0.16s linear 0s, opacity 0.08s linear 0s',
  },
  iconWrapper: {
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    top: -25,
  },
  avatar: {
    position: 'fixed',
    background: '#3D5673',
    height: '50px',
    width: '50px',
    boxShadow:
      '0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16)',
  },
}))

export default IntercomCustomBtn
