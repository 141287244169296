import React, { useContext, useReducer } from 'react'
import { reducer } from '../Reducers/Reducer'
import AppConfig from '../config/AppConfig'
import { StateInterface, StoreInterface } from '../Interfaces'

export const initialState: StateInterface = {
  appConfig: AppConfig,
  dol: null,
  notification: null,
  dolHashtagsCloudUsed: null,
  dolHashtagsUsed: null,
  dolAssociatedTags: null,
  dolAssociatedTopics: null,
  savedTwitterUser: {data: null, isLoading: true},
  savedTwitterUsers: null,
  tagSearchStrings: null,
  topic: null,
  topics: null,
  topicDolsByInfluence: null,
  topicDolsByAuthority: null,
  topicDolsByExternalUser: null,
  networkTopicsTopInfluencer: null,
  networkEgoTopics: null,
  tags: null,
  tag: null,
  user: null,
  userSearchResults: [],
  redirectUrl: null,
}

const StoreContext = React.createContext({
  state: initialState,
} as StoreInterface)
export const useStateContext = () => useContext(StoreContext)

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <StoreContext.Provider value={{ state, dispatch }} children={children} />
  )
}
