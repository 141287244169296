import React, { useEffect } from 'react'
import { Loader } from '@visformatics/components'
import './styles.scss'

const PageLoader = () => {
  return (
    <div className="page-loader">
      <Loader loading overlay />
    </div>
  )
}

export default PageLoader
