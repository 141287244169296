import axios from 'axios'
import AppConfig from '../config/AppConfig';
import { setApiHeader } from '../Helpers';

export const PostTag = async(dispatch, tag, token) => {
  const { global } = AppConfig;

  return axios
    .post(`${global.apiUrl}/tag`, tag, { headers: setApiHeader(token) })
    .then((res) => {
      dispatch({ type: 'fetch_tag', payload: res.data })
      dispatch({
        type: 'notification',
        payload: { message: 'Tag was created successfully', type: 'success' },
      })
      window.location.pathname = `/tag/${res.data.tag_id}`
    })
    .catch((err) => {
      dispatch({
        type: 'notification',
        payload: { message: err, type: 'error' },
      })
      console.error('An error occurred while submitting tag: ', err)
    })
}
