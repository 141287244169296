import { makeStyles } from '@material-ui/core'
import { margins } from './margins'
import { paddings } from './paddings'
import { fonts } from './fonts'
import { flex } from './flex'

/**
 * Export helper css classes
 */
export const globalStyles = makeStyles((theme) => ({
  // Fonts
  ...fonts,

  // Margins
  ...margins,

  // Paddings
  ...paddings,

  // Flex
  ...flex,

  // Width
  wFull: {
    width: '100%',
  },
  w300: {
    width: '300px',
  },
  w500: {
    width: '500px',
  },
  wHalf: {
    width: '50%',
  },
  wHalfViewport: {
    width: '50vw'
  },
  wFitContent: {
    width: 'fit-content'
  },
  // Height
  hFull: {
    height: '100%',
  },
  hHalf: {
    height: '50%',
  },
  minH400: {
    minHeight: '400px',
  },
  minH500: {
    minHeight: '500px',
  },
  h500px: {
    height: '500px',
  },

  // Position
  relative: {
    position: 'relative',
  },

  // Align
  vAlignMiddle: {
    verticalAlign: 'middle',
  },
  alignICenter: {
    alignItems: 'center',
  },
  selfStart: {
    alignSelf: 'flex-start',
  },
  selfCenter: {
    alignSelf: 'center',
  },

  // Overflow
  overflowYScroll: {
    overflowY: 'scroll',
  },

  // Opacity
  opacity80: {
    opacity: 0.8,
  },

  // Other
  whiteSpacePreWrap: {
    whiteSpace: 'pre-line',
  },
  hidden: {
    display: 'none',
  },
  block: {
    display: 'block',
  },
  breakWord: {
    wordBreak: 'break-word',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  iconColor: {
    color: '#FEA524',
  },
  bgWhite: {
    backgroundColor: 'white',
  },
  hyperLink: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  noTextDecoration: {
    textDecoration: 'none',
  },
  takedaIcon: {
    color: '#EE1100',
  },
  takedaAvatar: {
    backgroundColor: '#EE1100',
  },
  insightsWrapper: {
    [theme.breakpoints.up('md')]: {
      minHeight: '400px',
      width: '75%',
    },
  },
  listNone: {
    listStyle: 'none',
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
}))
