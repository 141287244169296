import React from 'react'

import './Image.css'

type Props = {
  alt?: string
  alternateImage?: string
  image: string
  round?: boolean
  size: 'large' | 'medium' | 'small'
}

export const Image = ({
  alt,
  image,
  round = false,
  size = 'medium',
}: Props) => {
  return (
    <div className={`imageContainer ${round ? 'round' : ''}`}>
      <img
        className={`${size} ${round ? 'round' : ''}`}
        src={image}
        alt={alt}
      />
    </div>
  )
}

export const ProfileImage = ({
  alt, alternateImage, image, round = false, size = 'medium'
}: Props) => {
  return (
    <div className={`imageContainer ${round ? 'round' : ''}`}>
      <img
        className={`${size} ${round ? 'round' : ''}`}
        src={image}
        alt={alt}
        onError={(e) => { e.target['src'] = alternateImage }}
      />
    </div>
  )
}
