import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Transition } from '@headlessui/react'
import { debounce } from 'lodash'
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from 'react-table'
import Loader from '../loader/loader'
import { PlusCircleIcon, RefreshIcon } from '@heroicons/react/outline'
import './table.scss'

const DEBOUNCE_DELAY = 300

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows?.length || 0
  const [value, setValue] = React.useState(globalFilter)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(
    debounce((value) => setGlobalFilter(value || undefined), DEBOUNCE_DELAY),
    [debounce, setGlobalFilter, DEBOUNCE_DELAY]
  )

  return (
    <div className="relative my-4 text-gray-600 sm:max-w-xs">
      <input
        value={value || ''}
        type="search"
        name="serch"
        className="h-10 px-5 pr-10 text-sm bg-white border border-gray-300 rounded-md shadow-lg text-secondary-600 focus:border-secondary-300 focus:ring focus:ring-offset-0 focus:ring-secondary-200 focus:ring-opacity-50"
        placeholder={`${count} records...`}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
      />
      <button type="submit" className="absolute top-0 right-0 mt-3 mr-4" />
    </div>
  )
}

// Our table component
function Table({ columns, data, title, addNew, refetch, isLoading }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  return (
    <div className="mb-10">
      <div className="title">{title}</div>
      <div className="items-center sm:justify-between sm:flex">
        <div>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
        {addNew || refetch ? (
          <div className="grid grid-flow-col gap-4">
            {refetch && (
              <div className="mb-4 sm:mb-0">
                <button
                  className="button button-icon"
                  onClick={() => refetch()}
                >
                  <RefreshIcon aria-hidden="true" /> Refresh
                </button>
              </div>
            )}
            {addNew && (
              <div className="mb-4 sm:mb-0">
                <button className="button button-icon" onClick={addNew}>
                  <PlusCircleIcon aria-hidden="true" /> Add new
                </button>
              </div>
            )}
          </div>
        ) : null}
      </div>
      <div className="relative">
        {isLoading && <Loader overlay={true} />}

        <table {...getTableProps()}>
          <thead>
            {page.map(() => {
              return headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))
            })}
          </thead>
          <tbody {...getTableBodyProps()} className="relative">
            {page.map((row) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <div className="block">
          Page{' '}
          <strong>
            {state.pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </div>
        <div className="buttons">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </button>{' '}
        </div>
        <select
          className="hidden sm:inline-block"
          value={state.pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[5, 10, 15, 20].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export function DataTable({
  isShowing,
  columns,
  data,
  title,
  addNew,
  refetch,
  isLoading,
}) {
  return (
    <Transition
      show={isShowing}
      appear={true}
      enter="transition-opacity duration-275"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-375"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="w-full h-full">
        {data && (
          <Table
            columns={columns}
            data={data}
            title={title}
            addNew={addNew}
            refetch={refetch}
            isLoading={isLoading}
          />
        )}
      </div>
    </Transition>
  )
}

DataTable.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
  addNew: PropTypes.func,
  refetch: PropTypes.func,
}
