import React from 'react';
import { usePagination } from '../../Hooks';
import PaginationLink from './PaginationLink';
import './Styles.scss';

interface PaginationProps {
  onClick: (number) => void
  totalCount: number
  pageSize: number
  currentPage: number
  disabled: boolean
}

const Pagination = ({onClick, totalCount, pageSize, currentPage, disabled}:PaginationProps) => {
  const siblingCount = 2;
  const paginationRange = usePagination({totalCount, pageSize, siblingCount, currentPage});
  const onNextClick = () => onClick(currentPage + 1);
  const onPreviousClick = () => onClick(currentPage - 1);
  const pages = paginationRange.length === 0 ? [1] : paginationRange

  return (
    <div className="results-pagination">
      <ul>
        <PaginationLink
          key='previous page'
          onClick={onPreviousClick}
          isDisabled={currentPage === 1 || disabled}
          label='&lt;'
          cssClass='not-bordered'
        />
        {pages.map((page, i) => {
          if (page === '...') return (<li key={`ellipsis ${i}`}>{page}</li>)
          return (
            <PaginationLink
              key={`page ${page}`}
              onClick={() => onClick(page)}
              isDisabled={currentPage === page || disabled}
              label={page.toString()}
              cssClass={currentPage === page ? 'selected' : ''}
            />
          );
        })}
        <PaginationLink
          key='next page'
          onClick={onNextClick}
          isDisabled={currentPage >= (totalCount / 100) || disabled}
          label='&gt;'
          cssClass='not-bordered'
        />
      </ul>
    </div>
  );
}

export default Pagination;
