import React, { useReducer } from 'react'
import { reducer } from './reducer'

const initialState = (window.Cypress && window.initialState) || {
  environment: null,
}
export const EnvironmentContext = React.createContext(initialState)
export const EnvironmentProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <EnvironmentContext.Provider value={{ state, dispatch }}>
      {props.children}
    </EnvironmentContext.Provider>
  )
}
