import PropTypes from 'prop-types'

export const SelectTa = ({ data, activeTa, setTa, columnKey }) => {
  return (
    <div className="block w-full pt-8 select-ta">
      <div className="container">
        <div className="title">Select TA</div>
        <div className="items-center gap-4 my-4 lg:gap-2 sm:flex-wrap sm:justify-start sm:flex">
          {data?.insights_service_therapy_areas?.map((ta, index) => (
            <button
              key={index}
              className={`button${
                activeTa === ta?.[columnKey] ? ' active' : ''
              }`}
              onClick={() => setTa(ta?.[columnKey])}
            >
              {ta?.display_name}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

SelectTa.defaultProps = {
  columnKey: 'identifier',
}

SelectTa.propTypes = {
  data: PropTypes.object,
  activeTa: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setTa: PropTypes.func,
  columnKey: PropTypes.string,
}

export default SelectTa
