import { useState } from 'react'
import { FlatButton } from '@visformatics/components'
import { TagFormInterface, TagInterface } from './interface'
import FormInput from '../../FormInput'
import './styles.scss'

const TagForm = ({
  cancelLabel,
  labels,
  onCancel,
  onSubmit,
  values,
}: TagFormInterface) => {
  const initialObject = {
    tag_id: values?.tag_id ?? '',
    nameValue: values?.tag_text ?? '',
    descriptionValue: values?.description ?? '',
  }

  const [tag, setTag] = useState(initialObject)

  const [invalid, setInvalid] = useState(!values?.tag_id)

  const { name, description } = labels

  const validTagDescription = false
  const validTagName = false

  const handleSubmit = () => {
    const tagObj: TagInterface = {
      tag_id: tag.tag_id,
      tag_text: tag.nameValue,
      description: tag.descriptionValue,
    }
    if (values?.tag_id) {
      tagObj.tag_id = values.tag_id
    }
    onSubmit(tagObj)
  }

  const handleTagAttributeChange = (valid, key, value) => {
    setTag({ ...tag, [key]: value })
    validate()
  }

  const validate = () => {
    setInvalid(
      !validTagName &&
        !validTagDescription &&
        !tag.nameValue &&
        !tag.descriptionValue
    )
  }

  return (
    <div className="tag-form">
      <FormInput
        error={name?.error}
        feedback={(valid, value) =>
          handleTagAttributeChange(valid, 'nameValue', value)
        }
        label={name?.label}
        required={true}
        value={tag.nameValue}
      />
      <FormInput
        error={description?.error}
        feedback={(valid, value) =>
          handleTagAttributeChange(valid, 'descriptionValue', value)
        }
        label={description?.label}
        required={true}
        value={tag.descriptionValue}
      />
      <div className="tag-form-container__actions">
        <FlatButton
          type="submit"
          click={handleSubmit}
          disabled={invalid}
          children="Submit"
        />
        <FlatButton type="button" click={onCancel} children={cancelLabel} />
      </div>
    </div>
  )
}

export default TagForm
