import {ActionTypes} from '../Types';
import {ActionTypesEnum} from '../Enums';
import {StateInterface} from '../Interfaces';
import {initialState} from '../Store/Store';

export const reducer = (state: StateInterface, action: ActionTypes) => {
  switch (action.type) {
    case ActionTypesEnum.FETCH_DOL:
      return {
        ...state,
        dol: action.payload,
      }
    case ActionTypesEnum.NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      }
    case ActionTypesEnum.USER_SEARCH:
      return {
        ...state,
        userSearchResults: action.payload,
      }
    case ActionTypesEnum.FETCH_DOL_USED_HASHTAGS:
      return {
        ...state,
        dolHashtagsUsed: action.payload,
      }
    case ActionTypesEnum.FETCH_DOL_USED_CLOUD_HASHTAGS:
      return {
        ...state,
        dolHashtagsCloudUsed: action.payload,
      }
    case ActionTypesEnum.FETCH_DOL_ASSOCIATED_TAGS:
      return {
        ...state,
        dolAssociatedTags: action.payload,
      }
    case ActionTypesEnum.FETCH_DOL_ASSOCIATED_TOPICS:
      return {
        ...state,
        dolAssociatedTopics: action.payload,
      }
    case ActionTypesEnum.FETCH_TOPICS:
      return {
        ...state,
        topics: action.payload,
      }
    case ActionTypesEnum.FETCH_TOPIC:
      return {
        ...state,
        topic: action.payload,
      }
    case ActionTypesEnum.FETCH_TOPIC_DOLS_BY_INFLUENCE:
      return {
        ...state,
        topicDolsByInfluence: action.payload,
      }
    case ActionTypesEnum.FETCH_TOPIC_DOLS_BY_AUTHORITY:
      return {
        ...state,
        topicDolsByAuthority: action.payload,
      }
    case ActionTypesEnum.FETCH_TOPIC_DOLS_BY_EXTERNAL_USER:
      return {
        ...state,
        topicDolsByExternalUser: action.payload,
      }
    case ActionTypesEnum.FETCH_TAGS:
      return {
        ...state,
        tags: action.payload,
      }
    case ActionTypesEnum.FETCH_TAG:
      return {
        ...state,
        tag: action.payload,
      }
    case ActionTypesEnum.FETCH_TAG_SEARCH_STRINGS:
      return {
        ...state,
        tagSearchStrings: action.payload,
      }
    case ActionTypesEnum.FETCH_TOPICS_NETWORK_TOP_INFLUENCER: {
      return {
        ...state,
        networkTopicsTopInfluencer: action.payload
      }
    }
    case ActionTypesEnum.FETCH_EGO_TOPICS_NETWORK: {
      return {
        ...state,
        networkEgoTopics: action.payload
      }
    }
    case ActionTypesEnum.SET_USER: {
      return {
        ...state,
        user: action.payload
      }
    }
    case ActionTypesEnum.FETCH_SAVED_TWITTER_USER: {
      return {
        ...state,
        savedTwitterUser: {data: action.payload, isLoading: false}
      }
    }
    case ActionTypesEnum.POST_SAVED_TWITTER_USER: {
      return {
        ...state,
        savedTwitterUser: {data: action.payload, isLoading: false}
      }
    }
    case ActionTypesEnum.RESET_SAVED_TWITTER_USER: {
      return {
        ...state,
        savedTwitterUser: initialState.savedTwitterUser
      }
    }
    case ActionTypesEnum.DELETE_SAVED_TWITTER_USER: {
      return {
        ...state,
        savedTwitterUser: {data: null, isLoading: false}
      }
    }
    case ActionTypesEnum.FETCH_SAVED_TWITTER_USERS: {
      return {
        ...state,
        savedTwitterUsers: action.payload
      }
    }
    case ActionTypesEnum.RESET_SAVED_TWITTER_USERS: {
      return {
        ...state,
        savedTwitterUsers: null
      }
    }
    case ActionTypesEnum.SET_REDIRECT_URL: {
      return {
        ...state,
        redirectUrl: action.payload
      }
    }
    default:
      return state
  }
}
