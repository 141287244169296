import axios from 'axios'
import AppConfig from '../config/AppConfig';
import { setApiHeader } from '../Helpers';
import jwt_decode from 'jwt-decode';


export const FetchTopicDolsExternalUser = async(dispatch, topic_id, measure, token, limit, offset) => {
  const { global } = AppConfig;
  const {sub} = jwt_decode<{ sub: string }>(token);

  return axios
    .get(`${global.apiUrl}/topic/${topic_id}/dols/external_user/${sub}`, { 
      params: { 
        influence_measure: measure, 
        limit: limit, 
        offset: offset 
      }, headers: setApiHeader(token) })
    .then((res) => {
      dispatch({type: 'fetch_topic_dols_by_external_user', payload: res.data})
    })
    .catch((err) => {
      dispatch({ type: 'notification', payload: { message: err, type: 'error' },
      })
    })
}
