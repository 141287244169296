import {range} from 'lodash';

const ELLIPSIS = '...';

export const buildPaginationArray = (
  position: string,
  siblingCount: number,
  totalPageCount: number,
  currentPage?: number
) => {
  let count;
  let positionRange;
  const firstPageIndex = 1;

  if (position === 'left' || position === 'right') {
    count = 3 + 2 * siblingCount;
  }

  if (position === 'left') {
    positionRange = range(1, count);
    return [...positionRange, ELLIPSIS, totalPageCount];
  }

  if (position === 'right') {
    positionRange = range(totalPageCount - count + 2, totalPageCount);
    return [firstPageIndex, ELLIPSIS, ...positionRange, totalPageCount];
  }

  const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
  const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

  positionRange = range(leftSiblingIndex, rightSiblingIndex);
  return [firstPageIndex, ELLIPSIS, ...positionRange, ELLIPSIS, totalPageCount];
}
