import BorderlessButtonProps from './interface'
import './style.scss'

const BorderlessButton = ({
  icon,
  caption,
  width = '18px',
  height = '18px',
  onClickEvent,
  altText,
  style,
}: BorderlessButtonProps) => {
  return (
    <button className="btn-borderless" onClick={onClickEvent}>
      <div className="btn-borderless-container">
        {icon && (
          <div className={`icon ${style ?? ''}`}>
            <img width={width} height={height} src={icon} alt={altText} />
          </div>
        )}
        {caption && <div className="caption">{caption}</div>}
      </div>
    </button>
  )
}

export default BorderlessButton
