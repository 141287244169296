import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  PutTopic,
  FetchTopic,
  FetchTopicDolDownload,
} from '../../../dispatchers';
import { useStateContext } from '../../../Store/Store';
import Toggle from '../../../components/Toggle';
import PageLoader from '../../../components/PageLoader';
import TopicHeader from '../../../components/Topics/Topic/TopicHeader';
import TopicDetails from '../../../components/Topics/Topic/TopicDetails';
import BackButton from '../../../components/BackButton';
import { FlatButton } from '@visformatics/components';
import './styles.scss';

const TopicPage = () => {
  const { id } = useParams<{ id: string }>();
  const { state, dispatch } = useStateContext();
  const {
    topic,
    appConfig: { global, topicStrings },
    user,
  } = state
  const { topicDefinition, form } = topicStrings
  const history = useHistory()

  useEffect(() => {
    FetchTopic(dispatch, id, user.token);
  }, [dispatch,user.token, id])

  const handleBackButtonClick = () => history.goBack()
  const handleEditClick = () => history.push(`/topic/${id}/edit`)
  const handleBrowseNetworkClick = () => history.push(`/topic/${id}/network`)

  const handleExcelDownloadClick = (event) => {
    event.preventDefault()
    FetchTopicDolDownload(
      dispatch,
      id,
      `DOLs-for-topic-${topic.topic_text}.xlsx`,
      user.token
    )
  }

  if (!topic) return <PageLoader />

  const { topic_definition } = topic

  const handleToggle = (e) => {
    const topicObject = {
      topic_id: topic.topic_id,
      topic_text: topic.topic_text,
      description: topic.description,
      topic_definition: {
        hashtags: topic.topic_definition.hashtags,
        search_string: topic.topic_definition.search_string,
      },
      active: !topic.active,
    }

    PutTopic(dispatch, topicObject, user.token);
  }

  return (
    <div className="topic">
      <div className="calls-to-action">
        <BackButton label={global.backButton} onClick={handleBackButtonClick} />
        <div className="calls-to-action_wrapper">
          <div className="calls-to-action_wrapper__toggle">
            <label htmlFor="active">{global.activeLabel}</label>
            <Toggle
              name="active"
              onChange={handleToggle}
              isChecked={topic.active}
            />
          </div>
          <FlatButton
            click={handleExcelDownloadClick}
            children={global.downloadSpreadsheet}
          />
          <FlatButton
            click={handleBrowseNetworkClick}
            children={global.browseNetworkLabel}
          />
          <FlatButton click={handleEditClick} children={global.editButton} />
        </div>
      </div>
      <TopicHeader title={topic.topic_text} description={topic.description} />
      <h2>{topicDefinition}:</h2>
      <TopicDetails labels={form.fields} details={topic_definition} />
    </div>
  )
}

export default TopicPage
