import React from 'react'
import PropTypes from 'prop-types'
import { getDate } from '@visformatics/utils'

const FORMAT = 'll'

const ReportingTable = ({ questions, dataColumns }) => {
  return (
    <div className="w-full h-full">
      <div className="w-full overflow-x-auto">
        <table className="w-full mx-auto overflow-hidden bg-white divide-y divide-gray-300 rounded-lg whitespace-nowrap">
          <thead className="bg-gray-900">
            <tr className="text-left text-white">
              {dataColumns.map(({ label }, index) => (
                <th
                  key={index}
                  className="px-6 py-4 text-sm font-semibold uppercase"
                >
                  {label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {questions.map((question, ind) => {
              return (
                <tr key={ind}>
                  {dataColumns.map(({ key }, index) => {
                    const value =
                      key === 'interview_start_time' || key === 'created_at'
                        ? getDate(question[key], FORMAT)
                        : question[key]
                    return (
                      <td className="px-6 py-4" key={index}>
                        {value}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            <tr></tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

ReportingTable.propTypes = {
  questions: PropTypes.array,
  dataColumns: PropTypes.array.isRequired,
}

export default ReportingTable
