import React from 'react'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Loader } from '@visformatics/components'

export const ProfileList = ({ data, loading, selected, setSelected }) => {
  return (
    <div className="relative block">
      {loading && <Loader loader={true} />}
      {data?.dmt_gateway_search_profiles.map((author, index) => (
        <label
          key={index}
          className={`flex items-center gap-4 p-2 cursor-pointer hover:bg-gray-100${
            selected === index ? ' bg-gray-200' : ''
          }`}
        >
          <input
            type="radio"
            className=""
            name="radio"
            checked={selected === index ? true : false}
            onChange={(e) => setSelected(index)}
          />
          {author?.profile_image && (
            <img
              alt={`${author?.person_name}`}
              src={`data:*/*;base64,${author?.profile_image}`}
              className="w-12 h-12 rounded-full"
            />
          )}
          <span className="block w-full ml-2">
            <span className="font-bold text-primary-600">
              {author?.person_name}
            </span>
            <span className="block w-full">
              {author?.disease_area}, {author?.region_name}&nbsp;
            </span>
          </span>
          {author?.impact_factor && (
            <span className="p-3 text-white rounded-full bg-primary-600">
              {Math.trunc(author?.impact_factor)}
            </span>
          )}
        </label>
      ))}
    </div>
  )
}
