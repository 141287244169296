import React from 'react'

import { Text } from '../../typography/text/Text'

import './Input.css'

type Props = {
  // tslint:disable-next-line
  change?: (event) => void
  error?: string
  blur?: () => void
  focus?: () => void
  info?: string
  // tslint:disable-next-line
  keyPress?: (event) => void
  labelText: string
  placeholder?: string
  type?: 'password' | 'text' | 'date'
  value?: string
  name?: string
}
export const Input = ({
  change,
  error,
  blur,
  focus,
  info,
  keyPress,
  labelText,
  placeholder,
  type,
  value,
  name,
}: Props) => {
  return (
    <>
      <label className="float__label">
        <input
          onChange={change}
          onBlur={blur}
          onFocus={focus}
          onKeyPress={keyPress}
          placeholder={placeholder ? placeholder : ' '}
          type={type ? type : 'text'}
          value={value}
          name={name}
          aria-label={labelText}
        />
        <span>{labelText}</span>
      </label>
      {error && <Text type="caption">{error}</Text>}
      {info && !error && <Text type="caption">{info}</Text>}
    </>
  )
}
