import React, { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

export const Select = ({
  value,
  label,
  optionLabel,
  optionKey,
  data,
  onChange,
  className,
}) => {
  const { register } = useForm()
  const getKey = (label) => {
    const index = data?.findIndex((column) => column[optionLabel] === label)
    return data && data[index] && data[index][optionKey]
  }
  const getLabel = (key) => {
    const index = data?.findIndex((column) => column[optionKey] === key)
    return data && data[index] && data[index][optionLabel]
  }
  const onChangeHandle = (label) => {
    onChange(getKey(label))
  }
  return (
    <Listbox
      value={value}
      onChange={onChangeHandle}
      className={`relative z-10 min-w-1/2 ${className}`}
    >
      <div className="relative mt-1">
        {label && <Listbox.Label>{label}</Listbox.Label>}
        <Listbox.Button className="relative z-10 w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
          <span className="block truncate text-primary-600">
            {getLabel(value)}&nbsp;
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 w-full max-w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {data?.length &&
              data.map((ta, index) => (
                <Listbox.Option
                  {...register('interview_start_time')}
                  key={index}
                  className={({ active }) =>
                    `${
                      active ? 'text-primary-900 bg-gray-200' : 'text-gray-600'
                    }
                      cursor-default select-none relative py-2 pl-10 pr-4`
                  }
                  value={ta[optionLabel]}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${
                          selected ? 'font-medium' : 'font-normal'
                        } block truncate`}
                      >
                        {ta[optionLabel]}
                      </span>
                      {selected ? (
                        <span
                          className={`${
                            active ? 'text-primary-600' : 'text-primary-600'
                          }
                            absolute inset-y-0 left-0 flex items-center pl-3`}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default Select
