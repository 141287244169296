import React from 'react'
import { useHistory } from 'react-router'
import { useStateContext } from '../../../../Store/Store'
import { ListTagsItemInterface } from './ListTagsItemInterface'
import { FlatButton } from '@visformatics/components'
import Toggle from '../../../Toggle'

const ListTagsItem = ({
  id,
  title,
  active,
  description,
  userMatchCount,
  updateTagAction,
}: ListTagsItemInterface) => {
  const history = useHistory()
  const { state } = useStateContext()
  const {
    appConfig: { global },
  } = state

  const handleEditAction = () => {
    // history.push(`/tag/${id}/edit`) won't force render
    window.location.pathname = `/tag/${id}/edit`
  }

  const handleViewAction = () => {
    history.push(`/tag/${id}`)
  }

  const updateTagHandler = () => {
    const tagObject = {
      tag_id: id,
      tag_text: title,
      description: description,
      active: !active,
    }

    updateTagAction(tagObject)
  }

  return (
    <div className="tags-item">
      <div className="tags-item-container">
        <div className="tags-item-container_main">
          {title && <h2>{title}</h2>}
          {description ? <p>{description}</p> : <p>No description provided</p>}
          {userMatchCount && (
            <h3>
              Mentioned by <span>{userMatchCount}</span> users
            </h3>
          )}
        </div>
        <div className="tags-item-container-wrapper">
          <div className="tags-item-container-wrapper_toggle">
            <label htmlFor="active">{global.activeLabel}</label>
            <Toggle
              name="active"
              onChange={updateTagHandler}
              isChecked={active}
            />
          </div>
          <div className="tags-item-container_actions">
            <FlatButton click={handleViewAction}>View</FlatButton>
            <FlatButton click={handleEditAction}>Edit</FlatButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListTagsItem
