import axios from 'axios'
import AppConfig from '../config/AppConfig';
import { setApiHeader } from '../Helpers';

export const FetchTopics = async(dispatch, token) => {
  const { global } = AppConfig

  return axios
    .get(`${global.apiUrl}/topics`, { headers: setApiHeader(token) })
    .then((res) => {
      dispatch({ type: 'fetch_topics', payload: res.data })
    })
    .catch((err) => {
      dispatch({
        type: 'notification',
        payload: { message: err, type: 'error' },
      })
      console.error('There was a problem fetching Topics: ', err)
      dispatch({ type: 'fetch_topics', payload: [] })
    })
}
