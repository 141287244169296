import { useMemo } from 'react';
import { range } from 'lodash';
import { UsePaginationType } from './types';
import { buildPaginationArray } from './helpers';

const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage
}: UsePaginationType) => {
  
  const paginationRange = useMemo(() => {
    let position;
    const totalPageCount = Math.ceil(totalCount / pageSize);
    const totalPageNumbers = siblingCount + 5;
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);
    const shouldShowLeftEllipsis = leftSiblingIndex > 2;
    const shouldShowRightEllipsis = rightSiblingIndex < totalPageCount - 2;
    
    if (totalPageCount <= totalPageNumbers) return range(1, totalPageCount);
    
    if (!shouldShowLeftEllipsis && shouldShowRightEllipsis) position = 'left';
    if (shouldShowLeftEllipsis && !shouldShowRightEllipsis) position = 'right';
    if (shouldShowLeftEllipsis && shouldShowRightEllipsis) position = 'middle';
    
    return buildPaginationArray(position, siblingCount, totalPageCount, currentPage);
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
}

export default usePagination;
