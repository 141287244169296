import { useEffect } from 'react';
import ListTags from '../../../components/Tags/List/ListTags';
import { useStateContext } from '../../../Store/Store';
import { FetchTags, PutTag } from '../../../dispatchers';
import './styles.scss'

const ListTagsPage = () => {
  const { state, dispatch } = useStateContext();
  const { tags, user } = state;

  useEffect(() => {
    if (!tags) FetchTags(dispatch, user.token)
  }, [dispatch, tags, user.token])

  const handleToggle = (tagObject) => PutTag(dispatch, tagObject, user.token, `/tags`);

  return (
    <div className="manage-tags">
      <ListTags tags={tags} updateTagAction={handleToggle} />
    </div>
  )
}

export default ListTagsPage
