// /external_user/{external_user_id}/favorite/twitter_user/{twitter_user_id}
import AppConfig from '../config/AppConfig';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import {setApiHeader} from '../Helpers';

export const DeleteSavedTwitterUser = (dispatch, token, dolId) => {
  const {global} = AppConfig
  const {sub} = jwt_decode<{ sub: string }>(token);

  return axios
    .delete(`${global.apiUrl}/external_user/${sub}/favorite/twitter_user/${dolId}`, {headers: setApiHeader(token)})
    .then(() => {
      dispatch({type: 'reset_saved_twitter_users'})
      dispatch({type: 'delete_saved_twitter_user'})
    })
    .catch((err) => {
      dispatch({type: 'notification', payload: {message: err, type: 'error'}})
      console.error('error when deleting saved twitter user: ', err)
    })
}
