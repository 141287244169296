import React, { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'

const HEADER_SIZE = '64px'

export default function SideNavbar({ open, setOpen, children }) {
  let childrenRef = useRef(null)

  return (
    <Transition.Root appear show={open} as={Fragment}>
      <Dialog
        as="div"
        initialFocus={childrenRef}
        className="fixed inset-0 z-30 overflow-hidden"
        open={open}
        onClose={setOpen}
        style={{ top: HEADER_SIZE }}
      >
        {/* Below line is to trick the dialog that it has a default ref */}
        <button className="absolute bottom-0 opacity-0"></button>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 transition-opacity bg-black bg-opacity-30" />
          </Transition.Child>
          <div
            className="fixed inset-y-0 left-0 flex max-w-full"
            style={{ top: HEADER_SIZE }}
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-200 sm:duration-300"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-200 sm:duration-300"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative w-screen max-w-xs">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-200"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="flex flex-col h-full overflow-y-auto bg-white shadow-xl">
                    <div ref={childrenRef} className="relative p-2">
                      {children}
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
