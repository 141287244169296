import ListItem from './ListTagsItem'
import { useHistory } from 'react-router-dom'
import { useStateContext } from '../../../Store/Store'
import { FlatButton } from '@visformatics/components'
import './styles.scss'

const ListTags = ({ tags, updateTagAction }) => {
  const history = useHistory()
  const { state, dispatch } = useStateContext()
  const {
    appConfig: {tagStrings, global },
  } = state

  const handleCreateTagAction = () => {
    history.push('/tag/create')
  }

  if (tags && tags.length > 0) {
    return (
      <div className="tags">
        <div className="tags_title">
          <h1>{tagStrings.title}</h1>
          <div className="actions_container">
            <FlatButton
              size="medium"
              children={global.createTag}
              click={handleCreateTagAction}
            />
          </div>
        </div>
        <ul>
          {tags.map((tag) => (
            <ListItem
              id={tag.tag_id}
              key={tag.tag_id}
              active={tag.active}
              title={tag.tag_text}
              userMatchCount={tag.tag_statistics.matching_user_count}
              description={tag.description}
              updateTagAction={updateTagAction}
            />
          ))}
        </ul>
      </div>
    )
  }

  return (
    <div className="tags">
      <div className="tags-no-results">
        <h2>{tagStrings.noTagsMessage}</h2>
      </div>
    </div>
  )
}

export default ListTags
