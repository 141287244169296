import React, { ReactNode } from 'react'

import './ButtonRow.css'

type Props = {
  buttons: ReactNode[]
  horizontal?: boolean
}

export const ButtonRow = ({ buttons, horizontal }: Props) => {
  const width = 100 / buttons.length
  return (
    <div className={horizontal ? 'button-row_horizontal' : 'button-row_column'}>
      {buttons &&
        buttons.map((button, index) => {
          return (
            <div
              key={`key-${index}`}
              className="button-row_grid button-row_gutter"
              style={{ width: horizontal ? `${width}%` : '100%' }}
            >
              {button}
            </div>
          )
        })}
    </div>
  )
}
