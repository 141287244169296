import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useStateContext } from '../../../Store/Store';
import { FetchTopic, PutTopic } from '../../../dispatchers';
import TopicForm from '../../../components/Topics/TopicForm';
import PageLoader from '../../../components/PageLoader';

const EditTopic = () => {
  const { id } = useParams<{ id: string }>();
  const {
    state: {
      appConfig: {
        global,
        topicStrings: {
          form: { fields },
        },
      },
      topic,
      user,
    },
    dispatch,
  } = useStateContext()
  const history = useHistory()

  useEffect(() => {
    FetchTopic(dispatch, id, user.token)
  }, [dispatch, user.token, id])

  if (!topic) return <PageLoader />

  const handleOnSubmit = (data) => PutTopic(dispatch, data, user.token);
  const handleCancel = () => history.goBack()

  return (
    <div className="topic-form-container">
      <h1>Edit {topic.topic_text}</h1>
      <TopicForm
        cancelLabel={global.cancelButton}
        labels={fields}
        onCancel={handleCancel}
        onSubmit={handleOnSubmit}
        values={topic}
      />
    </div>
  )
}

export default EditTopic
