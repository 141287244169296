import axios from 'axios'
import AppConfig from '../config/AppConfig';
import { setApiHeader } from '../Helpers';

export const FetchDol = async(dispatch, id, token) => {
  const { global } = AppConfig

  return axios
    .get(`${global.apiUrl}/user/by_id/${id}`, { headers: setApiHeader(token) })
    .then((res) => {
      dispatch({ type: 'fetch_dol', payload: res.data })
    })
    .catch((err) => {
      dispatch({ type: 'notification', payload: { message: err, type: 'error' }})
      console.error('error when fetching DOL information: ', err)
      dispatch({ type: 'fetch_dol', payload: {} })
    })
}
