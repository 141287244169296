import axios from 'axios'
import AppConfig from '../config/AppConfig';
import { setApiHeader } from '../Helpers';

export const FetchDolAssociatedTags = async(dispatch, id, token) => {
  const { global } = AppConfig

  return axios
    .get(`${global.apiUrl}/user/${id}/tags`, { headers: setApiHeader(token) })
    .then((res) => {
      dispatch({ type: 'fetch_dol_associated_tags', payload: res.data })
    })
    .catch((err) => {
      dispatch({ type: 'notification', payload: { message: err, type: 'error' }})
      console.error('Error when fetching associated tags for DOL: ', err)
      dispatch({ type: 'fetch_dol_associated_tags', payload: [] })
    })
}
