import React from 'react'
import PropTypes from 'prop-types'
import './button.scss'

export function Button(props) {
  return <button {...props}>{props.children}</button>
}

Button.propTypes = {
  children: PropTypes.node,
}

export default Button
