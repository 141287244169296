import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import './capture-navbar.scss'

const captureRoutes = [
  {
    label: 'Who',
    route: '/insights/create',
  },
  {
    label: 'When',
    route: '/insights/when',
  },
  {
    label: 'Insights',
    route: '/insights/insight',
  },
  {
    label: 'Review',
    route: '/insights/review',
  },
]
export const CaptureNavbar = () => {
  const location = useLocation()

  return (
    <nav className="container">
      <ul className="capture-navbar">
        {captureRoutes.map(({ label, route }, index) => (
          <li
            key={index}
            className={`mr-6${location.pathname === route ? ' active' : ''}`}
          >
            <NavLink
              to={route}
              className="px-5 py-3 bg-white shadow-lg link text-primary-600 hover:text-primary-900"
            >
              {label}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default CaptureNavbar
