import React from 'react'
import { Input } from '../input/Input'
import { Heading, Text } from '../../typography'
import { FlatButton } from '../../buttons/flat/FlatButton'
import './Login.css'

export const Login = () => {
  return (
    <form className="login_container">
      <Heading align="center" type="h2">
        Welcome
      </Heading>
      <Input labelText="Username" />
      <Input labelText="Password" type="password" />
      <FlatButton fill={true} size="medium" type="submit">
        Continue
      </FlatButton>
      <Text align="center" type="label">
        OR
      </Text>
      <FlatButton display="secondary" fill={true} size="medium" type="submit">
        Continue with Google
      </FlatButton>
    </form>
  )
}

export default Login
