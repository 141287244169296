import React from 'react'
import { NavLink } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Menu, Transition } from '@headlessui/react'

export const LogoutMenu = () => {
  const { user, logout } = useAuth0()

  return (
    <Menu>
      {({ open }) => (
        <>
          <span className="rounded-md shadow-sm">
            <Menu.Button className="flex flex-row-reverse items-center border-none cursor-pointer hover:opacity-75 custom-transition focus:outline-none">
              <img
                src={user?.picture}
                alt={'Avatar'}
                className="w-10 h-10 rounded-full"
              />

              <span className="invisible mr-2 text-lg md:visible">
                {user?.name}
              </span>
            </Menu.Button>
          </span>

          <Transition
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
            >
              <div className="py-1">
                <Menu.Item>
                  <NavLink
                    to="/"
                    className="link"
                    activeClassName="active-link"
                    exact
                  >
                    Home
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink
                    to="/form"
                    className="link"
                    activeClassName="active-link"
                    exact
                  >
                    Form Builder
                  </NavLink>
                </Menu.Item>
              </div>

              <div className="py-1">
                <Menu.Item as="span" disabled className="link disabled-link">
                  New feature (soon)
                </Menu.Item>
              </div>

              <div className="py-1">
                <Menu.Item>
                  <a
                    href="#sign-out"
                    onClick={() =>
                      logout({ returnTo: `${window.location.origin}/login` })
                    }
                    className="link"
                  >
                    Sign out
                  </a>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export default LogoutMenu
