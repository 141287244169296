import axios from 'axios'
import AppConfig from '../config/AppConfig';
import { setApiHeader } from '../Helpers';

export const PutTag = async(dispatch, tag, token, redirectUrl?) => {
	const { global } = AppConfig;
	
	return axios.put(`${global.apiUrl}/tag`, tag, { headers: setApiHeader(token) })
		.then((res) => {
			dispatch({ type: 'notification', payload: { message: 'Tag updated successfully', type: 'success' } })
			window.location.pathname = (redirectUrl) ? redirectUrl : `/tag/${tag.tag_id}`
		})
		.catch((err) => {
			dispatch({ type: 'notification', payload: { message: err, type: 'error' } })
			console.error(`A problem occured while updating Tag ID ${tag.id}: `, err)
		})
}
