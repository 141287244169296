import React from 'react'
import './styles.scss'
import { useStateContext } from '../../Store/Store'

const Notification = ({ notification }) => {
  const { state } = useStateContext()

	const { appConfig: { global } } = state

	const message = () => {
		if ( notification.type === 'error') {
			return `${global.pageError}: ${notification.message}`
		}

		return notification.message
	}	

	if (notification) {
    return (
      <div className={`notification notification__${notification.type}`}>
				<h3>{message()}</h3>
			</div>
    )
  }

  return null
}

export default Notification
