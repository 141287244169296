import { useState } from 'react'
import { FlatButton } from '@visformatics/components'

import './styles.scss'
import FormInput from '../../FormInput'
import { TopicFormInterface, TopicInterface } from './interface'

const TopicForm = ({
  cancelLabel,
  labels,
  onCancel,
  onSubmit,
  values,
}: TopicFormInterface) => {

  const initialObject = {
    topic_id: values?.topic_id ?? '',
    nameValue: values?.topic_text ?? '',
    descriptionValue: values?.description ?? '',
    fromDateValue: values?.topic_definition?.from_date ?? '',
    toDateValue: values?.topic_definition?.to_date ?? '',
    searchStringValue: values?.topic_definition?.search_string ?? '',
    hashtagsValue: values?.topic_definition?.hashtags?.join(', ') ?? '',
  }

  const [topic, setTopic] = useState(initialObject)

  const [validTopicHashtags, setValidTopicHashtags] = useState(false)
  const [invalid, setInvalid] = useState(!values?.topic_id)
  const [validTopicSearchString, setValidTopicSearchString] = useState(false)

  const {
    description,
    fromDate,
    hashtags,
    name,
    searchString,
    submit,
    toDate,
  } = labels

  const validOptional = false
  const validTopicDescription = false
  const validTopicName = false

  const handleClick = () => {
    const topicObj: TopicInterface = {
      topic_id: topic.topic_id,
      topic_text: topic.nameValue,
      description: topic.descriptionValue,
      topic_definition: {
        from_date: topic.fromDateValue,
        hashtags: topic.hashtagsValue.split(',').map((tag) => tag.trim()),
        search_string: topic.searchStringValue,
        to_date: topic.toDateValue,
      },
    }
    if (values?.topic_id) {
      topicObj.topic_id = values.topic_id
    }

    onSubmit(topicObj)
  }

  const handleTopicOptionalFeedback = () => {
    const optional = !topic.hashtagsValue && !topic.searchStringValue;
    setValidTopicHashtags(optional)
    setValidTopicSearchString(optional);
    validate();
  }

  const handleTopicHashtagsFeedback = (valid, value) => {
    handleTopicAttributeChange(valid, 'hashtagsValue', value)
    handleTopicOptionalFeedback();
  }

  const handleTopicSearchStringFeedback = (valid, value) => {
    handleTopicAttributeChange(valid, 'searchStringValue', value)
    handleTopicOptionalFeedback();
  }

  const handleTopicAttributeChange = (valid, key, value) => {
    setTopic({ ...topic, [key]: value })
    validate()
  }

  const validate = () => {
    setInvalid(
      !validTopicName &&
        !validTopicDescription &&
        !topic.hashtagsValue &&
        !topic.searchStringValue
    )
  }

  return (
    <div className="topic-form">
      <FormInput
        error={name?.error}
        feedback={(valid, value) =>
          handleTopicAttributeChange(valid, 'nameValue', value)
        }
        label={name?.label}
        required={true}
        value={topic.nameValue}
      />
      <FormInput
        error={description?.error}
        feedback={(valid, value) =>
          handleTopicAttributeChange(valid, 'descriptionValue', value)
        }
        label={description?.label}
        required={true}
        value={topic.descriptionValue}
      />
      <FormInput
        displayError={validTopicHashtags}
        error={hashtags?.error}
        feedback={(valid, value) => handleTopicHashtagsFeedback(valid, value)}
        label={`${hashtags?.label}, ${hashtags?.placeholder}`}
        required={false}
        value={topic.hashtagsValue}
      />
      <FormInput
        displayError={validTopicSearchString}
        error={searchString?.error}
        feedback={(valid, value) =>
          handleTopicSearchStringFeedback(valid, value)
        }
        label={searchString?.label}
        required={false}
        value={topic.searchStringValue}
      />
      <div className="date-fields">
        <FormInput
          feedback={(valid, value) =>
            handleTopicAttributeChange(valid, 'fromDateValue', value)
          }
          label={fromDate?.label}
          type="date"
          required={false}
          value={topic.fromDateValue}
        />
        <FormInput
          feedback={(valid, value) =>
            handleTopicAttributeChange(valid, 'toDateValue', value)
          }
          label={toDate?.label}
          type="date"
          required={false}
          value={topic.toDateValue}
        />
      </div>
      <FlatButton
        type="submit"
        click={handleClick}
        disabled={invalid}
        children={submit?.label}
      />
      <FlatButton type="button" click={onCancel} children={cancelLabel} />
    </div>
  )
}

export default TopicForm
