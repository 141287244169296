import { createElement, ReactNode } from 'react'

import './Heading.css'

type Props = {
  align?: 'center' | 'left' | 'right'
  children: ReactNode
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

export const Heading = ({ align, children, type }: Props) => {
  const element = !type ? 'h1' : type
  const className = `heading ${element} ${!align ? 'left' : align}`
  return createElement(element, { className }, children)
}

export default Heading
