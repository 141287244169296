import React from 'react'
import { FlatButton } from '@visformatics/components'
import './styles.scss'

const BackButton = ({label, onClick}) => {
  return (
    <div className="back-button">
      <FlatButton click={onClick} >
        <span>{label}</span>
      </FlatButton>
    </div>
  )
}

export default BackButton
