import { useState } from 'react';
import { useStateContext } from '../../../Store/Store';
import { PutTagSearchString, DeleteTagSearchString } from '../../../dispatchers';
import BorderlessButton from '../../../components/BorderlessButton';
import { Input } from '@visformatics/components';

import Modal from '../../../components/Modal';
import saveIcon from '../../../assets/save-icon.svg';
import cancelIcon from '../../../assets/cross-icon.svg';
import editIcon from '../../../assets/edit-icon.svg';
import removeIcon from '../../../assets/remove-icon.svg';

export const SearchStringItem = ({
  string_id,
  tag_id,
  value,
}: {
  string_id: number
  tag_id: number
  value: string
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [showModal, setShowModal] = useState(false);
  const { state, dispatch } = useStateContext();
  const [showSaveEditModal, setShowSaveEditModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { user } = state;

  const handleDeleteStringAction = (e, searchStringId, id) => {
    e.preventDefault();
    DeleteTagSearchString(dispatch, searchStringId, id, user.token);
  }

  // 'pencil (edit)' button event handler
  const changeEditModeHandler = (event) => {
    const { value } = event.target
    setInputValue(value)
  }

  // modal window 'Confirm' action handler
  const handleSaveAction = () => {
    setShowSaveEditModal(false)
    const searchString = {
      search_string_id: string_id,
      search_string: inputValue,
      
    };
    
    PutTagSearchString(dispatch, searchString, user.token);
    setEditMode(false)
  }

  // modal window 'Cancel' action handler
  const handleCancelAction = () => {
    setInputValue(value)
    setEditMode(false)
  }

  const handleSaveCheckAction = () => {
    return inputValue === value
      ? setEditMode(false)
      : setShowSaveEditModal(true)
  }

  return (
    <div className="item-wrapper">
      <div className="search-strings-container-list_item">
        {editMode ? (
          <Input
            labelText=""
            value={inputValue}
            change={changeEditModeHandler}
          />
        ) : (
          <h4>{inputValue}</h4>
        )}
      </div>
      {editMode ? (
        <div className="item-wrapper_actions">
          <BorderlessButton
            altText="save"
            icon={saveIcon}
            onClickEvent={handleSaveCheckAction}
          />
          {showSaveEditModal && (
            <Modal
              title="Action required"
              textContent="You are about to make changes. Are you sure?"
              onConfirm={handleSaveAction}
              onCancel={handleCancelAction}
              closeable={true}
            />
          )}
          <BorderlessButton
            altText="cancel"
            icon={cancelIcon}
            onClickEvent={handleCancelAction}
          />
        </div>
      ) : (
        <div className="item-wrapper_actions">
          <BorderlessButton
            altText="edit"
            icon={editIcon}
            onClickEvent={() => setEditMode(true)}
          />
          <BorderlessButton
            altText="delete"
            icon={removeIcon}
            onClickEvent={() => setShowModal(true)}
          />
          {showModal && (
            <Modal
              title="Action required"
              textContent="Would you like to remove this search string?"
              onConfirm={(event) => {
                handleDeleteStringAction(event, string_id, tag_id)
              }}
              onCancel={() => setShowModal(false)}
              closeable={true}
            />
          )}
        </div>
      )}
    </div>
  )
}
